import { Suspense } from "react";
import { Route } from "react-router-dom";
import { LazyLoading } from "../../controls/LazyLoading";
import DelaysManager from "../../pages/delays/manager";
import DelaysParetoReport from "../../pages/delays/pareto";
import DelaysReport from "../../pages/delays/report";

export const DelayRoutes = [
  <Route
    path="/delays-manager"
    element={
      <Suspense fallback={<LazyLoading></LazyLoading>}>
        <DelaysManager />
      </Suspense>
    }
  />,
  <Route
    path="/delays-manager/:shiftId"
    element={
      <Suspense fallback={<LazyLoading></LazyLoading>}>
        <DelaysManager />
      </Suspense>
    }
  />,
  <Route
    path="/delays-report/"
    element={
      <Suspense fallback={<LazyLoading></LazyLoading>}>
        <DelaysReport />
      </Suspense>
    }
  />,
  <Route
    path="/delays-pareto/"
    element={
      <Suspense fallback={<LazyLoading></LazyLoading>}>
        <DelaysParetoReport />
      </Suspense>
    }
  />,
];
