import { MenuOptionType } from "@dexteel/mesf-core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Carrier } from "../../models/Carrier";

type Props = {
  setSelectedRowData: (carrier: Carrier) => void;
  setShowModal: (modal: "assembly" | "disassembly" | "") => void;
};
export const useCarrierAssemblyOptionsFunctions = ({
  setSelectedRowData,
  setShowModal,
}: Props) => {
  const getMenuOptions = (data: Carrier) => {
    let options: MenuOptionType[] = [];
    options = options.concat([
      {
        name: "Assembly Carrier",
        key: "assembly_carrier",
        onClick: () => {
          setSelectedRowData(data);
          setShowModal("assembly");
        },
        icon: <EditIcon />,
      },
    ]);
    if (data.AssemblyStatusCode === "A") {
      options = options.concat([
        {
          name: "Disassembly Carrier",
          key: "disassembly_carrier",
          onClick: () => {
            setSelectedRowData(data);
            setShowModal("disassembly");
          },
          icon: <DeleteIcon />,
        },
      ]);
    }
    return options;
  };
  return {
    getMenuOptions,
  };
};
