import { ErrorModal } from "@dexteel/mesf-core";
import { CircularProgress, IconButton, TextField } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Section } from "../models/Section";
import { getSections } from "../repositories/SectionRepository";

type Props = {
  label: string;
  value: number | null;
  onChange?: Function;
  disabled?: boolean;
};

const useSections = (partId?: number, onError?: (error: Error) => void) => {
  return useQuery<Section[], Error>({
    queryKey: ["sections", partId],
    queryFn: () => getSections(partId),
    onError: onError,
  });
};

export const SectionSelector = ({
  label,
  value,
  onChange = () => {},
  disabled = false,
}: Props) => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { data: sections, isLoading } = useSections(undefined, (error) =>
    setError(error.message)
  );

  const selectedValue =
    sections?.find((section) => section.SectionId === value) || null;

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={sections || []}
        getOptionLabel={(option) => option.SectionName}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <>
                      {params.InputProps.endAdornment}
                      {value ? (
                        <IconButton
                          onClick={() =>
                            navigate(`/configuration/sections/${value}`)
                          }
                          size="small"
                          title="See in sections"
                        >
                          <VisibilityIcon />
                        </IconButton>
                      ) : null}
                    </>
                  )}
                </>
              ),
            }}
          />
        )}
        value={selectedValue}
        onChange={(event, newValue) => {
          onChange(newValue?.SectionId ?? null);
        }}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading sections"
      />
    </>
  );
};
