import { ErrorModal, useContextMenuMESF } from "@dexteel/mesf-core";
import { Grid, Typography } from "@material-ui/core";
import {
  GetContextMenuItemsParams,
  GridApi,
  IRowNode,
  MenuItemDef,
  RowSelectedEvent,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";

import { useGridDefinitions } from "../../../../controls/ag-grid/components/GridDefinitions";
import { CenteredLazyLoading } from "../../../../controls/LazyLoading";

import { useNavigate } from "react-router-dom";
import { TextFieldSearchFilter } from "../../../../shared/components/TextFieldSearchFilter";
import { useGridStyles } from "../../../configuration/styles/gridStyles";
import { Carrier } from "../../models/Carrier";
import { useCarriersAssembly } from "../CarrierAssemblyPage";
import { useCarrierAssemblyOptionsFunctions } from "../hooks/useCarrierAssemblyOptionsFunctions";
import { useTableData } from "../hooks/useTableData";
import { CarrierAssemblyDetails } from "./CarrierAssemblyDetails";
import { CarrierDisassemblyModal } from "./CarrierDisassemblyModal";

export const TableCarrierAssembly = ({
  partId,
}: {
  partId: string | undefined;
}) => {
  const classes = useGridStyles();
  const navigate = useNavigate();

  const [filterValue, setFilterValue] = useState("");
  const [selectedRowData, setSelectedRowData] = useState<Carrier | null>(null);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [showModal, setShowModal] = useState<"assembly" | "disassembly" | "">(
    ""
  );
  const [error, setError] = useState("");

  const { data: rows, isLoading } = useCarriersAssembly(undefined, (error) =>
    setError(error.message)
  );

  const { showContextMenu, registerConfig } = useContextMenuMESF();
  const { getMenuOptions } = useCarrierAssemblyOptionsFunctions({
    setSelectedRowData,
    setShowModal,
  });
  const { columnDefs } = useTableData({
    showContextMenu,
  });

  const { columnDefaults } = useGridDefinitions({
    OnEdit: (data: any) => {
      setShowModal("assembly");
      setSelectedRowData(data);
    },
  });

  const onRowSelected = (event: RowSelectedEvent) => {
    if (event.node.isSelected()) {
      setSelectedRowData(event.data);
      setShowModal("assembly");
      navigate(`/carriers-assembly/${(event.data as Carrier).PartId}`);
    }
  };

  const getContextMenuItems = React.useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      const data = params.node?.data;
      showContextMenu(event as any, data, "carrierAssemblyContext");
      if (data) {
        params.api.deselectAll();
        params.node?.setSelected(true);
      }
      return [];
    },
    [rows]
  );

  const onModalHide = ({
    shouldUpdate,
    close,
  }: {
    shouldUpdate: boolean;
    close: boolean;
  }) => {
    if (shouldUpdate) gridApi?.paginationGoToLastPage();
    if (close) {
      setShowModal("");
      setSelectedRowData(null);
      if (gridApi) gridApi?.deselectAll();
    }
  };

  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
      if (partId) {
        let partNode: IRowNode<any>;

        gridApi?.forEachNode((n) => {
          if ((n.data as Carrier).PartId === Number(partId)) {
            partNode = n;
            return true;
          }
        });

        if (partNode!) {
          const pageSize = gridApi?.paginationGetPageSize();
          const pageNumber = Math.floor((partNode!.rowIndex ?? 0) / pageSize!);
          gridApi?.paginationGoToPage(pageNumber);

          partNode.setSelected(true);
          setSelectedRowData(partNode.data);
          setShowModal("assembly");
        }
      }
    }
  }, [isLoading, gridApi]);

  useEffect(() => {
    registerConfig({
      id: "carrierAssemblyContext",
      getOptions: getMenuOptions,
    });
  }, []);

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
          <Typography
            variant="h5"
            style={{ margin: "15px 0 10px", fontWeight: 600 }}
          >
            Carriers Assembly
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} style={{ margin: "10px 18px" }}>
          <TextFieldSearchFilter
            value={filterValue}
            onChange={(val) => {
              setFilterValue(val);
              gridApi?.setQuickFilter(val);
            }}
          />
        </Grid>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          className={classes.root}
          style={{ width: "100%", padding: "0 10px" }}
          spacing={2}
        >
          <Grid item md={5} xs={12}>
            <div
              style={{ height: "78vh", width: "100%" }}
              className={`${classes.table} ag-theme-alpine`}
            >
              <AgGridReact<Carrier>
                rowData={rows || []}
                columnDefs={columnDefs}
                defaultColDef={columnDefaults}
                rowHeight={34}
                headerHeight={34}
                animateRows={true}
                pagination={true}
                rowSelection="single"
                paginationAutoPageSize
                getContextMenuItems={getContextMenuItems}
                onGridReady={(params) => setGridApi(params.api)}
                getRowId={(params) => String(params.data.PartId)}
                loadingOverlayComponent={CenteredLazyLoading}
                onRowSelected={onRowSelected}
              />
            </div>
          </Grid>
          <Grid item md={7} xs={12}>
            <div
              style={{ height: "78vh", width: "100%" }}
              className={`${classes.table} ag-theme-alpine`}
            >
              <CarrierAssemblyDetails
                carrier={selectedRowData}
                onHide={onModalHide}
                modal={showModal}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
      <CarrierDisassemblyModal
        carrier={selectedRowData!}
        show={showModal === "disassembly"}
        onHide={() => {
          setShowModal("");
          setSelectedRowData(null);
          if (gridApi) gridApi?.deselectAll();
        }}
      />
    </>
  );
};
