import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Carrier } from "../../../../../pages/assembly/models/Carrier";

export const getCarriers = async (partId: number) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [
    { name: "PartId", value: partId }
  ];


  const resp = await apiService.callV2("[M2].[GetCarriers]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as Carrier[];
  } else {
    throw new Error(resp.message || "Error fetching carrier");
  }
};
