import {
	type DropOptions,
	type NodeModel,
	isAncestor,
} from "@minoru/react-dnd-treeview";
import { useEffect } from "react";
import { FileProperties } from "../../../models/tree/FileProperties";
import { updateParentLocation } from "../../../repositories/CatalogLocationRepository";

type Props = {
	location: NodeModel<FileProperties>[];
	selectedNodes: NodeModel<FileProperties>[];
	isCtrlPressing: boolean;
	setIsCtrlPressing: Function;
	setSelectedNodes: Function;
	setIsDragging: Function;
	onLocationUpdateEnd: (
		result: { ok: true } | { ok: false; message: string },
	) => void;
	onLocationUpdateStart?: () => void;
};

export const useLocationActions = ({
	location,
	selectedNodes,
	setSelectedNodes,
	isCtrlPressing,
	setIsDragging,
	setIsCtrlPressing,
	onLocationUpdateEnd,
	onLocationUpdateStart = () => {},
}: Props) => {
	useEffect(() => {
		const handleKeyDown = (e: any) => {
			if (e.key.toLowerCase() === "escape") {
				setSelectedNodes([]);
			} else if (e.ctrlKey || e.metaKey) {
				setIsCtrlPressing(true);
			}
		};

		const handleKeyUp = (e: any) => {
			if (e.key.toLowerCase() === "control" || e.key.toLowerCase() === "meta") {
				setIsCtrlPressing(false);
			}
		};

		window.addEventListener("keydown", handleKeyDown);
		window.addEventListener("keyup", handleKeyUp);

		return () => {
			window.removeEventListener("keydown", handleKeyDown);
			window.removeEventListener("keyup", handleKeyUp);
		};
	}, []);

	const handleDrop = async (
		_: NodeModel<FileProperties>[],
		options: DropOptions<FileProperties>,
	) => {
		let { dropTargetId } = options;

		if (dropTargetId == 0) {
			dropTargetId = 1;
		}

		onLocationUpdateStart();
		const result = await updateParentLocation(
			selectedNodes.map((cn) => cn.id),
			dropTargetId as number,
		);

		if (result.ok) {
			setSelectedNodes([]);
		}

		onLocationUpdateEnd(result);
	};

	const handleSingleSelect = (node: NodeModel<FileProperties>) => {
		setSelectedNodes([node]);
	};

	const handleMultiSelect = (clickedNode: NodeModel<FileProperties>) => {
		const selectedIds = selectedNodes.map((n) => n.id);

        // If we select an ancestor, we deselect the others
		if (
			selectedIds.some((selectedId) =>
				isAncestor(location, selectedId, clickedNode.id),
			)
		) {
			return;
		}

		let updateNodes = [...selectedNodes];

		updateNodes = updateNodes.filter((selectedNode) => {
			return !isAncestor(location, clickedNode.id, selectedNode.id);
		});

		updateNodes = [...updateNodes, clickedNode];

        // If we select an already selected node, we deselected
        if (selectedIds.includes(clickedNode.id)) {
            setSelectedNodes(updateNodes.filter((node) => node.id !== clickedNode.id))
            return;
		}

		setSelectedNodes(updateNodes);
	};

	const handleCtrlClick = (
		e: React.MouseEvent,
		node: NodeModel<FileProperties>,
	) => {
		if (e.ctrlKey || e.metaKey) {
			handleMultiSelect(node);
		} else {
			handleSingleSelect(node);
		}
	};

	const handleDragStart = (node: NodeModel<FileProperties>) => {
		const isSelectedNode = selectedNodes.some((n) => n.id === node.id);
		setIsDragging(true);

		if (!isCtrlPressing && isSelectedNode) {
			return;
		}

		if (!isCtrlPressing) {
			setSelectedNodes([node]);
			return;
		}

		if (!selectedNodes.some((n) => n.id === node.id)) {
			setSelectedNodes([...selectedNodes, node]);
		}
	};

	const handleDragEnd = () => {
		setIsDragging(false);
		setIsCtrlPressing(false);
	};
	return { handleDrop, handleCtrlClick, handleDragEnd, handleDragStart };
};
