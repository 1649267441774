import { Grid, IconButton, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { SectionGap } from "../../../models/Section";

const useStyles = makeStyles(() => ({
  buttons: {
    opacity: 0,
    transition: "opacity 0.1s ease-in-out",
    ".ag-row-hover &": {
      opacity: 1,
    },
    ".ag-row-focus &": {
      opacity: 1,
    },
    ".ag-row-selected &": {
      opacity: 1,
    },
  },
}));

const cellStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

type Props = {
  setOpenEdit: (open: boolean) => void;
  setSelectedData?: (data: any) => void;
};

export const useSectionsGapsGridData = ({
  setOpenEdit,
  setSelectedData = () => {},
}: Props) => {
  const classes = useStyles();

  const columnDefs: ColDef[] = [
    {
      field: "StandName",
      headerName: "Stand",
      cellStyle,
      flex: 1,
    },
    {
      field: "SetpointGap",
      headerName: "Setpoint Gap",
      cellStyle,
      flex: 1,
    },
    {
      field: "Comments",
      headerName: "Comments",
      cellStyle,
      flex: 1,
    },
    {
      field: "-",
      cellRenderer: (params: ICellRendererParams) => (
        <Grid
          container
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className={classes.buttons}
        >
          <Grid
            item
            xs={12}
            md={12}
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                setOpenEdit(true);
                setSelectedData(params.data as SectionGap);
              }}
              title="Edit"
            >
              <EditIcon style={{ height: "auto" }} color="primary" />
            </IconButton>
          </Grid>
        </Grid>
      ),
      wrapText: false,
      autoHeight: false,
      wrapHeaderText: false,
      headerName: "",
      sortable: false,
      flex: 1,
      maxWidth: 180,
      filter: false,
      autoHeaderHeight: true,
    },
  ];
  return { columnDefs };
};
