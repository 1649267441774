import { ErrorModal, getMomentTz } from "@dexteel/mesf-core";
import { Grid } from "@material-ui/core";
import { DateTimeField } from "@mui/x-date-pickers";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Chock } from "../../../../configuration/models/parts/Chock";
import { Part } from "../../models/Part";
import { getChocks } from "../../repositories/parts/ChockRepository";

const moment = getMomentTz();

type useChocksProps = {
  partId: number;
  onError: (error: Error) => void;
  onSuccess?: (data: Chock[]) => void;
};

export const useChocks = ({ partId, onError, onSuccess }: useChocksProps) => {
  return useQuery<Chock[], Error>({
    queryKey: ["chocks", partId],
    queryFn: () => getChocks(partId),
    onError: onError,
    onSuccess: onSuccess,
  });
};

type Props = {
  part: Part | null;
};

export const SpecificChockData = ({ part }: Props) => {
  const [error, setError] = useState("");
  const [chock, setChock] = useState<Chock>();

  const { data: chocks } = useChocks({
    partId: part?.PartId!,
    onError: (error) => setError(error.message),
    onSuccess: (data: Chock[]) => {
      setChock(data[0]);
    },
  });

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={12} sm={12} xs={12}>
          <DateTimeField
            value={
              chock?.RebuildTimestamp
                ? moment.utc(chock.RebuildTimestamp)
                : null
            }
            label="Rebuild Date"
            variant="outlined"
            size="small"
            fullWidth
            readOnly
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <DateTimeField
            value={
              chock?.BearingChangeTimestamp
                ? moment.utc(chock.BearingChangeTimestamp)
                : null
            }
            label="Bearing Change Date"
            variant="outlined"
            size="small"
            fullWidth
            readOnly
          />
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
