import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, TextField } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { optionsGuideCode } from "../../../../../shared/selectors/GuideTypeSelector";
import { Guide } from "../../../../configuration/models/parts/Guide";
import { Part } from "../../models/Part";
import { getGuides } from "../../repositories/parts/GuideRepository";

type useGuidesProps = {
  partId: number;
  onError: (error: Error) => void;
  onSuccess?: (data: Guide[]) => void;
};

export const useGuides = ({ partId, onError, onSuccess }: useGuidesProps) => {
  return useQuery<Guide[], Error>({
    queryKey: ["guides", partId],
    queryFn: () => getGuides(partId),
    onError: onError,
    onSuccess: onSuccess,
  });
};

type Props = {
  part: Part | null;
};

export const SpecificGuideData = ({ part }: Props) => {
  const [error, setError] = useState("");
  const [guide, setGuide] = useState<Guide>();

  const { data: guides } = useGuides({
    partId: part?.PartId!,
    onError: (error) => setError(error.message),
    onSuccess: (data: Guide[]) => {
      setGuide(data[0]);
    },
  });

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            value={
              optionsGuideCode.find(
                (type) => type.Code === guide?.GuideTypeCode
              )?.Name || ""
            }
            label="Type"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            value={guide?.Sections || ""}
            label="Sections"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            value={guide?.Stands || ""}
            label="Stands"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
