import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

type Props = {
  value: string;
  onChange: (value: string) => void;
};

export const TextFieldSearchFilter = ({ value, onChange }: Props) => {
  return (
    <TextField
      fullWidth
      label="Search"
      variant="outlined"
      size="small"
      margin="dense"
      value={value}
      onChange={(event) => {
        onChange(event?.target.value);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton edge="start" type="submit">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
