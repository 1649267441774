import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Carrier } from "../models/Carrier";
import { getCarriers } from "../repositories/CarrierAssemblyRepository";
import { TableCarrierAssembly } from "./components/TableCarrierAssembly";

export const useCarriersAssembly = (
  partId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<Carrier[], Error>({
    queryKey: ["carriersAssembly", partId],
    queryFn: () => getCarriers(partId),
    onError: onError,
  });
};

export const CarrierPage = () => {
  const { partId } = useParams();

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1}>
            <TableCarrierAssembly partId={partId} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
