import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Rollset } from "../models/Rolllset";
import { FilterSearch } from "./FilterRollsRepository";

export const getRollsets = async (data?: FilterSearch) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [
    { name: "LocationId", value: data?.StandId ? data?.StandId : null },
    { name: "SectionId", value: data?.SectionId ? data?.SectionId : null},
    { name: "RollTypeCode", value: data?.RollTypeCode ? data?.RollTypeCode : null },
  ];

  const resp = await apiService.callV2("[M2].[GetReadyRollsets]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as Rollset[];
  } else {
    throw new Error(resp.message || "Error fetching rollsets");
  }
};
