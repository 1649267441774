import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, makeStyles, TextField } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import {
  AgGridEvent,
  ColDef,
  ColumnApi,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.min.css";
import { AgGridReact } from "ag-grid-react";
import { isNil } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getSections } from "../../pages/configuration/repositories/SectionRepository";
import { Section } from "../models/Section";
import { getSectionsByPart } from "../repositories/SectionRepository";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .ag-icon-menu": {
      display: "none",
    },
    "& .ag-header-cell-label": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "2vh",
    },
    "& .ag-header-cell-text": {
      textAlign: "center !important",
    },

    "& .ag-checkbox-input-wrapper": {
      margin: "0 10px",
    },
    "& .ag-cell": {
      alignItems: "center",
      padding: "0 !important",
      border: "unset !important",
      textAlign: "center",
    },
    "& .ag-header-cell": {
      padding: "0px !important",
      textAlign: "center !important",
      fontSize: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .ag-cell-wrapper": {
      textAlign: "center",
    },
    "& .ag-menu-option": {
      cursor: "pointer",
    },
    "& .MuiDataGrid-row": {
      cursor: "pointer",
    },
    "& .MuiDataGrid-columnHeaderTitle, & .MuiTablePagination-caption": {
      fontWeight: "bold",
      fontSize: "12px",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: "0",
    },
    "& .MuiDataGrid-cell--textLeft": {
      fontSize: "12px",
    },
  },
}));

interface Props {
  sectionsOfPart: Section[];
  onSelectionChanged: Function;
}

type useSectionsByPartProps = {
  partId: number;
  onError?: (error: Error) => void;
  onSuccess?: (data: Section[]) => void;
};

type useSectionsProps = {
  sectionId?: number;
  onError?: (error: Error) => void;
  onSuccess?: (data: Section[]) => void;
};

export const useSectionsByPart = ({
  partId,
  onError,
  onSuccess,
}: useSectionsByPartProps) => {
  return useQuery<Section[], Error>({
    queryKey: ["sectionsByPart", partId],
    queryFn: () => getSectionsByPart(partId),
    onError: onError,
    onSuccess: onSuccess,
  });
};

export const useSections = ({
  sectionId,
  onError,
  onSuccess,
}: useSectionsProps) => {
  return useQuery<Section[], Error>({
    queryKey: ["sections", sectionId],
    queryFn: () => getSections(sectionId),
    onError: onError,
    onSuccess: onSuccess,
  });
};

export const SectionsOfPartSelector = ({
  sectionsOfPart,
  onSelectionChanged,
}: Props) => {
  const classes = useStyles();

  const [filterInput, setFilterInput] = useState("");
  const [allSections, setAllSections] = useState<Section[]>([]);
  const [error, setError] = useState<string>("");

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null);

  const { data: sections } = useSections({
    onError: (error) => setError(error.message),
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setAllSections([...data]);
      }
    },
  });

  const handlerFilter = (e: any) => {
    setFilterInput(e.target.value);
  };

  const sortRows = (event: AgGridEvent) => {
    event.columnApi.applyColumnState({
      state: [{ colId: "SectionName", sort: "asc", sortIndex: 0 }],
    });
  };

  const rows = useMemo(() => {
    return allSections.map(({ SectionId, SectionName, SectionFamilyName }) => {
      return {
        SectionId: SectionId,
        SectionName: SectionName,
        SectionFamilyName: SectionFamilyName,
      };
    });
  }, [allSections]);

  const columns: ColDef[] = [
    {
      field: "",
      flex: 1,
      maxWidth: 100,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      sortIndex: 1,
      sortable: false,
    },
    {
      field: "SectionName",
      headerName: "Section",
      flex: 2,
      sortIndex: 0,
      comparator: (a: any, b: any, nodeA, nodeB, isInverted) => {
        if (nodeA.isSelected() && !nodeB.isSelected()) {
          return -1;
        } else if (!nodeA.isSelected() && nodeB.isSelected()) {
          return 1;
        } else {
          return nodeA.data.SectionName?.localeCompare(nodeB.data.SectionName);
        }
      },
    },
    {
      field: "SectionFamilyName",
      headerName: "Family",
      flex: 2,
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      resizable: false,
      filter: false,
      sortable: true,
    }),
    []
  );

  const onAgGridSelectionChanged = (event: SelectionChangedEvent) => {
    sortRows(event);
    const selectedRows = event.api.getSelectedRows();
    onSelectionChanged(selectedRows);
  };

  const onFirstDataRendered = useCallback(
    (params: FirstDataRenderedEvent) => {
      params.api.forEachNode((node) => {
        node.setSelected(
          !!node.data &&
            !isNil(
              sectionsOfPart.find((p) => p.SectionId === node.data.SectionId)
            )
        );
      });
      sortRows(params);
    },
    [sortRows]
  );

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      setGridApi(event.api);
      setColumnApi(event.columnApi);
    },
    [setGridApi]
  );

  useEffect(() => {
    gridApi?.setQuickFilter(filterInput.trim());
  }, [filterInput, gridApi]);

  return (
    <>
      <Grid
        container
        md={12}
        xs={12}
        style={{ padding: "0 0 10px", justifyContent: "space-between" }}
      >
        <Grid item md={12} xs={12}>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            value={filterInput}
            onChange={handlerFilter}
            margin="dense"
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <div className={classes.root}>
        <div
          className="ag-theme-alpine"
          style={{ width: "100%", height: "40vh" }}
        >
          <AgGridReact
            getRowId={(params) => params.data.SectionId}
            onGridReady={onGridReady}
            rowHeight={25}
            rowData={rows}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            onFirstDataRendered={onFirstDataRendered}
            rowSelection={"multiple"}
            animateRows
            rowMultiSelectWithClick
            onSelectionChanged={onAgGridSelectionChanged}
          />
        </div>
      </div>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
