import { Grid } from "@material-ui/core";
import { Moment } from "moment";
import React, { useEffect } from "react";
import { DelayAreaFilter } from "../../../shared/components/DelayAreaFilter";
import {
  EndFilter,
  StartFilter,
} from "../../../shared/components/StartEndFilter";

type Props = {
  onFilterChange: (startDate: Moment, endDate: Moment) => void;
  initialStartDate: Moment;
  initialEndDate: Moment;
  selectedDelayArea: number | null;
  onDelayAreaChange: (assetId: number | null) => void;
};

export const Filters: React.FC<Props> = ({
  onFilterChange,
  initialStartDate,
  initialEndDate,
  selectedDelayArea,
  onDelayAreaChange,
}) => {
  const [startDate, setStartDate] = React.useState<Moment>(initialStartDate);
  const [endDate, setEndDate] = React.useState<Moment>(initialEndDate);

  useEffect(() => {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
  }, [initialStartDate, initialEndDate]);

  const handleStartDateChange = (date: Moment | null) => {
    if (date) {
      setStartDate(date);
      onFilterChange(date, endDate);
    }
  };

  const handleEndDateChange = (date: Moment | null) => {
    if (date) {
      setEndDate(date);
      onFilterChange(startDate, date);
    }
  };

  return (
    <Grid container style={{ alignItems: "center" }} spacing={2}>
      <Grid item xs={12} md={2}>
        <StartFilter value={startDate} onChange={handleStartDateChange} />
      </Grid>
      <Grid item xs={12} md={2}>
        <EndFilter value={endDate} onChange={handleEndDateChange} />
      </Grid>
      <Grid item xs={12} md={2}>
        <DelayAreaFilter
          value={selectedDelayArea}
          onChange={onDelayAreaChange}
        />
      </Grid>
    </Grid>
  );
};
