import { getMomentTz, MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Chock } from "../../models/parts/Chock";

const moment = getMomentTz();

export const getChocks = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetChocks]", parameters);
	if (resp.ok){

		let rows = get(resp, "data.tables[0].rows", []);
	
		rows = rows.map((chock: Chock) => ({
		  ...chock,
		  RebuildTimestamp: chock.RebuildTimestamp ? moment.utc(chock["RebuildTimestamp"]).toDate() : null,
		  BearingChangeTimestamp: chock.BearingChangeTimestamp ? moment.utc(chock["BearingChangeTimestamp"]).toDate() : null
		}));
		
		return rows as Chock[]
	} else {
		throw new Error(resp.message || "Error fetching chocks")
	}
};
export const upsertChock = async (chock: Chock) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: chock.PartId },
		{ name: "PartName", value: chock.PartName },
		{ name: "LocationId", value: chock.LocationId },
		{ name: "Comments", value: chock.Comments },
		{ name: "RebuildTimestamp", value: chock?.RebuildTimestamp ? chock?.RebuildTimestamp : null },
		{ name: "BearingChangeTimestamp", value: chock?.BearingChangeTimestamp ? chock?.BearingChangeTimestamp : null }
	];

	const resp = await apiService.callV2("[M2].[UpsertChock]", parameters);

	if (resp.ok){
		return get(resp, "data.returnValue", null) as number;
	}
	else {
		throw new Error(resp.message || "Error upserting chocks");
	}
};

export const deleteChock = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	const resp = await apiService.callV2("[M2].[DeleteChock]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error deleting chocks");
	}
};