import { ErrorModal, getMomentTz } from "@dexteel/mesf-core";
import {
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Moment } from "moment-timezone";
import { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { GridApi } from "ag-grid-community";
import { NotificationSnackBar } from "../../../controls/snackbars/notification-snackbar";
import { DelaysReportTable } from "./components/DelaysReportTable";
import { Filters } from "./components/filters/Filters";
import { DelayByShift } from "./models/DelayByShift";
import { getDelays } from "./repositories/DelaysReportRepository";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "15px 20px 10px",
    borderRadius: "0.75rem",
    height: "91vh",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  filterContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: theme.spacing(2),
  },
  filterGroup: {
    display: "flex",
    alignItems: "flex-end",
  },
  buttonGroup: {
    display: "flex",
    gap: theme.spacing(2),
  },
  button: {
    whiteSpace: "nowrap",
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
    width: "100%",
  },
  tableContainer: {
    width: "100%",
    height: "calc(100% - 20px)",
    padding: "2px",
    "& .ag-root-wrapper": {
      border: "none",
    },
    "& .ag-header": {
      borderTop: "1px solid #e0e0e0",
    },
  },
}));

const moment = getMomentTz();

type useDelaysProps = {
  startDate: Date;
  endDate: Date;
  delayAreaAssetId?: number | null;
  includeMicrodelays?: boolean;
  shift?: string;
  crew?: string;
  onError: (error: Error) => void;
};

export const useDelays = ({
  startDate,
  endDate,
  delayAreaAssetId,
  includeMicrodelays = false,
  shift,
  crew,
  onError,
}: useDelaysProps) => {
  return useQuery<DelayByShift[], Error>({
    queryKey: ["delays"],
    queryFn: () =>
      getDelays({
        startDate,
        endDate,
        delayAreaAssetId,
        includeMicrodelays,
        shift,
        crew,
      }),
    onError: onError,
    enabled: false, // Only execute with refetch
  });
};

export const DelaysReportPage = () => {
  const classes = useStyles();

  const [error, setError] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");

  const [filterStartDate, setFilterStartDate] = useState<Moment>(
    moment().startOf("day")
  );
  const [filterEndDate, setFilterEndDate] = useState<Moment>(
    moment().endOf("day")
  );
  const [selectedDelayArea, setSelectedDelayArea] = useState<number | null>(
    null
  );
  const [filterCrew, setFilterCrew] = useState<string | null>("All");
  const [filterShift, setFilterShift] = useState<string | null>("All");
  const [includeMicrodelays, setIncludeMicrodelays] = useState<boolean>(false);

  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const {
    data: delays,
    refetch,
    isLoading,
  } = useDelays({
    startDate: filterStartDate.toDate(),
    endDate: filterEndDate.toDate(),
    delayAreaAssetId: selectedDelayArea,
    includeMicrodelays,
    shift: filterShift as string,
    crew: filterCrew as string,
    onError: (e) => setError(e.message),
  });

  const handleFilterChange = (startDate: Moment, endDate: Moment) => {
    setFilterStartDate(startDate);
    setFilterEndDate(endDate);
  };

  const handleDelayAreaChange = (assetId: number | null) => {
    setSelectedDelayArea(assetId);
  };

  const handleIncludeMicrodelaysChange = (include: boolean) => {
    setIncludeMicrodelays(include);
  };

  const handleSearch = () => {
    if (!filterStartDate || !filterEndDate) {
      setError("Please select both start and end dates.");
      return;
    }
    refetch();
  };

  const handleExportToExcel = () => {
    if (gridApi) {
      const params = {
        skipHeader: false,
        skipFooters: true,
        skipGroups: true,
        fileName: "delays_report.xlsx",
      };
      gridApi.exportDataAsExcel(params);
    } else {
      setError("Unable to export data. Please try again.");
    }
  };

  return (
    <>
      <Paper elevation={1} className={classes.root}>
        <Typography
          variant="h5"
          className={classes.header}
          style={{ fontWeight: 600 }}
        >
          Delays Report
        </Typography>
        <Grid container>
          <Grid item md={10}>
            <div className={classes.filterGroup}>
              <Filters
                onFilterChange={handleFilterChange}
                initialStartDate={filterStartDate}
                initialEndDate={filterEndDate}
                includeMicrodelays={includeMicrodelays}
                onIncludeMicrodelaysChange={handleIncludeMicrodelaysChange}
                selectedDelayArea={selectedDelayArea}
                onDelayAreaChange={handleDelayAreaChange}
                filterCrew={filterCrew as string}
                onFilterCrewChange={setFilterCrew}
                filterShift={filterShift}
                onFilterShiftChange={setFilterShift}
              />
            </div>
          </Grid>
          <Grid item md={2}>
            <div className={classes.buttonGroup}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
                className={classes.button}
              >
                Search
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleExportToExcel}
                disabled={!delays || delays.length === 0}
                className={classes.button}
              >
                Export to Excel
              </Button>
            </div>
          </Grid>
        </Grid>
        <Divider style={{ marginBottom: 10, marginTop: 10 }} />
        <div className={classes.tableContainer}>
          <DelaysReportTable
            isLoading={isLoading}
            rows={delays || []}
            gridApi={gridApi}
            setGridApi={setGridApi}
          />
        </div>
      </Paper>
      <ErrorModal
        error={error}
        onHide={() => {
          setError("");
        }}
      />
      <NotificationSnackBar
        message={notificationMessage}
        onHide={() => setNotificationMessage("")}
      />
    </>
  );
};
