import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  colorCircle: {
    width: "26px",
    height: "26px",
    borderRadius: 4,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontWeight: "bold",
  },
});

export const actionRequiredCodes = [
  { Code: "N", Name: "None" },
  { Code: "S", Name: "Soft Change" },
  { Code: "F", Name: "Full Change" },
];

type StatusTagProps = {
  status: { Code: string; Name: string };
};
export const ActionRequiredTag = ({ status }: StatusTagProps) => {
  const classes = useStyles();
  if (!status || !status.Code || !status.Name) {
    return null;
  }
  let backgroundColor;
  if (
    status.Code === actionRequiredCodes.find((code) => code.Code === "N")?.Code // None
  ) {
    backgroundColor = "#009460"; // Green
  } else if (
    status.Code === actionRequiredCodes.find((code) => code.Code === "S")?.Code // Soft Change
  ) {
    backgroundColor = "#fad215"; // Yellow
  } else if (
    status.Code === actionRequiredCodes.find((code) => code.Code === "F")?.Code // Full Change
  ) {
    backgroundColor = "#ce2029"; // Red
  }

  return (
    <Tooltip title={status.Name} arrow>
      <div className={classes.colorCircle} style={{ backgroundColor }}>
        {status.Code.charAt(0).toUpperCase()}
      </div>
    </Tooltip>
  );
};
