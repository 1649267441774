import { Button, CircularProgress, Grid } from "@material-ui/core";
type Props = {
  isSubmitLoading: boolean;
  disabled?: boolean;
  onHide: (update: boolean) => void;
  className?: string;
};
export const FooterDetailsButtons = ({
  isSubmitLoading,
  disabled = false,
  onHide,
  className,
}: Props) => {
  return (
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid item md={3} xs={12} style={{ margin: 0 }}>
        <Button
          fullWidth
          variant="contained"
          color="default"
          onClick={() => onHide(false)}
          className={className}
        >
          Close
        </Button>
      </Grid>
      <Grid item md={3} xs={12} style={{ margin: 0 }}>
        <Button
          fullWidth
          startIcon={isSubmitLoading && <CircularProgress size="1rem" />}
          disabled={isSubmitLoading || disabled}
          variant="contained"
          color="primary"
          type="submit"
          className={className}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};
