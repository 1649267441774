import { ErrorModal, getMomentTz } from "@dexteel/mesf-core";
import {
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@material-ui/core";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FooterDetailsButtons } from "src/controls/buttons/FooterDetailsButtons";
import { Chock, CHOCK_INITIAL_VALUES } from "../../../../models/parts/Chock";
import { upsertChock } from "../../../../repositories/parts/ChockRepository";
import { PartCommentsField } from "../../../../shared/components/PartCommentsField";
import { PartFields } from "../../PartFields";

type Props = {
  chock: Chock | null;
  modal: "create" | "update" | "delete" | "";
  onHide: ({
    shouldUpdate,
    close,
    entityId,
  }: {
    shouldUpdate: boolean;
    close: boolean;
    entityId?: number;
  }) => void;
};

const moment = getMomentTz();

export const ChockDetails = ({ chock, modal, onHide }: Props) => {
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const succesfullyMessage = `The chock was ${modal}d succesfully`;

  const {
    control,
    reset,
    handleSubmit,
    getValues,
    formState: { dirtyFields },
  } = useForm<Chock>({
    mode: "onChange",
  });
  const isFormDirty =
    !(Object.keys(dirtyFields).length === 0) || modal == "create";

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(upsertChock, {
    onSuccess: async (id: number) => {
      setNotificationMessage(succesfullyMessage);
      await queryClient.invalidateQueries(["chocks"]);
      onHide({ shouldUpdate: true, close: false, entityId: id });
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<Chock> = async (data) => {
    setIsSubmitLoading(true);
    if (modal !== "delete") {
      if (chock?.IsDuplicate) {
        upsertMutation.mutate({ ...data, PartId: null });
      } else {
        upsertMutation.mutate(data);
      }
    }
  };

  const validateNotInFuture = (description: string, value: Date | null) => {
    if (value && value > new Date()) {
      return `${description} time cannot be in the future.`;
    }
    return true;
  };

  useEffect(() => {
    if (modal !== "") {
      reset(chock || CHOCK_INITIAL_VALUES);
      setIsSubmitLoading(false);
    }
  }, [modal, chock]);

  return (
    <>
      {chock === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a chock or create a new one</Typography>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <PartFields<Chock>
              control={control}
              disabled={modal === "delete"}
            />
            <Grid item xs={12} md={6}>
              <Controller
                name="RebuildTimestamp"
                control={control}
                shouldUnregister
                rules={{
                  validate: {
                    notInFuture: (value) =>
                      validateNotInFuture("Rebuild", value),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <DatePicker
                      disabled={modal === "delete"}
                      label="Rebuild Date"
                      format="MM/DD/YYYY"
                      views={["year", "month", "day"]}
                      value={field.value ? moment(field.value) : null}
                      onChange={field.onChange}
                      slotProps={{
                        textField: {
                          size: "small",
                          error: !!error,
                        },
                        field: { clearable: true },
                      }}
                    />
                    {error && <FormHelperText>{error.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="BearingChangeTimestamp"
                control={control}
                shouldUnregister
                rules={{
                  validate: {
                    notInFuture: (value) =>
                      validateNotInFuture("Bearing Change", value),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <DatePicker
                      disabled={modal === "delete"}
                      label="Bearing Change Date"
                      format="MM/DD/YYYY"
                      views={["year", "month", "day"]}
                      value={field.value ? moment(field.value) : null}
                      onChange={field.onChange}
                      slotProps={{
                        textField: {
                          size: "small",
                          error: !!error,
                        },
                        field: { clearable: true },
                      }}
                    />
                    {error && <FormHelperText>{error.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={12} sm={12}>
              <Controller
                name="Comments"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PartCommentsField
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    getValues={getValues}
                    disabled={modal === "delete"}
                  />
                )}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ margin: 15 }}
            >
              <FooterDetailsButtons
                isSubmitLoading={isSubmitLoading}
                disabled={!isFormDirty}
                onHide={() => onHide({ shouldUpdate: false, close: true })}
              />
            </Grid>
          </Grid>
        </form>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
