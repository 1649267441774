export interface Section {
	SectionId: number | null;
	SectionName: string;
	SectionFamilyId: number | null;
	SectionFamilyName: string;
	IsActive: boolean | null;
}

export const SECTION_INITIAL_VALUES: Section = {
	SectionId: null,
	SectionName: '',
	SectionFamilyId: null,
	SectionFamilyName: "",
	IsActive: true
};

export interface SectionGap {
	SectionId: string;
	StandId: number | null;
	StandName: number | null;
	SetpointGap: number;
	Comments: string;
}