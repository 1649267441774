import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { TableParts } from "./components/TableParts";
import { Part } from "./models/Part";
import { getParts } from "./repositories/PartsReportRepository";
import { usePageStyles } from "./styles/pageStyles";

type usePartsProps = {
  onError?: (error: Error) => void;
};

export const useParts = ({ onError }: usePartsProps) => {
  return useQuery<Part[], Error>({
    queryKey: ["parts"],
    queryFn: () => getParts(),
    onError: onError,
  });
};

export const PartsReportPage = () => {
  const classes = usePageStyles();

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableParts />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
