import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { SpareCarrier } from "../models/SpareCarrier";
import { getReadyCarriers } from "../repositories/SparesRepository";
import { TableSpare } from "./components/TableSpare";

type useReadyCarriersProps = {
  onError: (error: Error) => void;
  sectionId: number;
  isNextSection?: boolean;
  onSuccess?: (data: any[]) => void;
};

export const useReadyCarriers = ({
  sectionId,
  isNextSection,
  onError,
}: useReadyCarriersProps) => {
  return useQuery<SpareCarrier[], Error>({
    queryKey: ["readyCarriers", sectionId, isNextSection],
    queryFn: () => getReadyCarriers(sectionId, isNextSection),
    onError: onError,
  });
};

export const SparePage = () => {
  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1}>
            <TableSpare />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
