import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Donut } from "../../models/parts/Donut";

export const getDonuts = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetDonuts]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as Donut[];
	}
	else {
		throw new Error(resp.message || "Error fetching donuts");
	}
};
export const upsertDonut = async (donut: Donut) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: donut.PartId },
		{ name: "PartName", value: donut.PartName },
		{ name: "LocationId", value: donut.LocationId },
		{ name: "DonutStatusCode", value: donut.DonutStatusCode },
		{ name: "Diameter", value: Number(donut.Diameter) },
		{ name: "NewDiameter", value: Number(donut.NewDiameter) },
		{ name: "ScrapDiameter", value: Number(donut.ScrapDiameter) },
		{ name: "Comments", value: donut.Comments },
	];

	const resp = await apiService.callV2("[M2].[UpsertDonut]", parameters);

	if (resp.ok){
		return get(resp, "data.returnValue", null) as number;
	}
	else {
		throw new Error(resp.message || "Error upserting donut");
	}
};

export const deleteDonut = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	const resp = await apiService.callV2("[M2].[DeleteDonut]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error deleting donut");
	}
};