import {
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterList";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { ErrorModal } from "@dexteel/mesf-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FooterDetailsButtons } from "../../../../controls/buttons/FooterDetailsButtons";
import { NotificationSnackBar } from "../../../../controls/snackbars/notification-snackbar";
import {
  LocationSelector,
  RollSelector,
  SectionSelector,
} from "../../../../shared/selectors";
import {
  Rollset,
  ROLLSET_INITIAL_VALUES,
  RollsetRolls,
} from "../../models/Rollset";
import {
  upsertRollset,
  upsertRollsetRolls,
} from "../../repositories/RollsetRepository";
import { useRollsetRolls } from "../RollsetPage";

const useStyles = makeStyles(() => ({
  errorLabel: {
    fontSize: 12,
    color: "#F44336",
  },
  infoLabel: {
    fontSize: 12,
    color: "#757575",
  },
}));

type Props = {
  rollset: Rollset | null;
  modal: "create" | "update" | "delete" | "";
  onHide: ({
    shouldUpdate,
    close,
    entityId,
  }: {
    shouldUpdate: boolean;
    close: boolean;
    entityId?: number;
  }) => void;
};

export const assemblyStatusCodes = [
  { Code: "E", Name: "Empty" },
  { Code: "A", Name: "Assembly In Progress" },
  { Code: "R", Name: "Ready" },
  { Code: "S", Name: "Disassembled In Progress" },
  { Code: "I", Name: "Inhibited" },
];

export const RollsetDetails = ({ rollset, modal, onHide }: Props) => {
  const classes = useStyles();

  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  // Filters
  const [standFilter, setStandFilter] = useState<number | null>(null);
  const [sectionFilter, setSectionFilter] = useState<number | null>(null);

  const succesfullyMessage = `The rollset was ${modal}d succesfully`;

  const {
    control,
    reset,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { dirtyFields },
  } = useForm<Rollset>({
    mode: "onChange",
  });

  const isFormDirty = !(Object.keys(dirtyFields).length === 0);

  const { data: rollsetRolls } = useRollsetRolls({
    rollsetId: rollset?.RollsetId!,
    onError: (error) => setError(error.message),
    onSuccess: (data: RollsetRolls[]) => {
      setValue(
        "TopRollId",
        data.find((roll) => roll.IsDefaultTopRoll)?.PartId || null
      );
      setValue(
        "BottomRollId",
        data.find((roll) => roll.IsDefaultBottomRoll)?.PartId || null
      );
    },
  });

  const validateRolls = (value: number | null, otherFieldName: any) => {
    const otherValue = watch(otherFieldName);
    return (
      value !== otherValue ||
      value === null ||
      value === undefined ||
      `Cannot be the same as ${
        otherFieldName === "TopRollId" ? "Top" : "Bottom"
      } Roll`
    );
  };

  const queryClient = useQueryClient();
  const upsertRollsMutation = useMutation(upsertRollsetRolls, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["rollsets"]);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });
  const upsertMutation = useMutation(upsertRollset, {
    onSuccess: async (id: number) => {
      await queryClient.fetchQuery(["rollsets", null]);
      setNotificationMessage(succesfullyMessage);
      onHide({ shouldUpdate: true, close: false, entityId: id });
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<Rollset> = async (data) => {
    setIsSubmitLoading(true);
    if (modal !== "delete")
      upsertMutation.mutate({
        rollset: data,
        TopRollId: getValues("TopRollId")!,
        BottomRollId: getValues("BottomRollId")!,
      });
  };

  useEffect(() => {
    if (modal !== "delete") {
      reset(rollset || ROLLSET_INITIAL_VALUES);
      setIsSubmitLoading(false);
    }
  }, [modal, rollset]);

  return (
    <>
      {rollset === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a rollset</Typography>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <div style={{ display: "flex", gap: "10px" }}>
                <FilterIcon style={{ color: "grey" }} />
                <Typography style={{ color: "grey" }}>Filters</Typography>
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <SectionSelector
                label="Section"
                value={sectionFilter}
                onChange={(val: number) => setSectionFilter(val)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <LocationSelector
                label="Stand"
                value={standFilter}
                onChange={(val: number) => setStandFilter(val)}
                onlyStands
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Divider style={{ marginBottom: 5, marginTop: 5 }} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="TopRollId"
                control={control}
                rules={{
                  required: "Top Roll is required",
                  validate: {
                    crossValidate: (value) =>
                      validateRolls(value, "BottomRollId"),
                  },
                  deps: ["BottomRollId"],
                }}
                render={({ field, fieldState: { error } }) => (
                  <RollSelector
                    label="Top Roll"
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    filterRolls={"T"}
                    params={{
                      sectionId: sectionFilter,
                      standId: standFilter,
                    }}
                    onForceAssembly={() => {}}
                    calledFrom="rollset"
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="BottomRollId"
                control={control}
                rules={{
                  required: "Bottom Roll is required",
                  validate: {
                    crossValidate: (value) => validateRolls(value, "TopRollId"),
                  },
                  deps: ["TopRollId"],
                }}
                render={({ field, fieldState: { error } }) => (
                  <RollSelector
                    label="Bottom Roll"
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    filterRolls={"B"}
                    params={{
                      sectionId: sectionFilter,
                      standId: standFilter,
                    }}
                    onForceAssembly={() => {}}
                    calledFrom="rollset"
                  />
                )}
              />
            </Grid>
            <Grid item md={12} sm={12} style={{ marginBottom: 10 }}>
              <Controller
                name="Comments"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <TextField
                      label="Comments"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={field?.value ?? ""}
                      maxRows={3}
                      minRows={3}
                      multiline
                      autoComplete="off"
                      disabled={modal === "delete"}
                      onChange={(event) => {
                        if (event.target.value.length <= 200) {
                          field.onChange(event.target.value);
                        }
                      }}
                      error={!!error}
                      helperText={error?.message}
                    />
                    {(getValues("Comments")?.length as number) !== 200 && (
                      <span
                        className={classes.infoLabel}
                        style={{ marginLeft: 5 }}
                      >
                        {`${getValues("Comments")?.length ?? 0}/200 max.`}
                      </span>
                    )}
                    {(getValues("Comments")?.length as number) == 200 && (
                      <span
                        className={classes.infoLabel}
                        style={{ marginLeft: 5 }}
                      >
                        Max. 200
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ margin: 15 }}
            >
              <FooterDetailsButtons
                isSubmitLoading={isSubmitLoading}
                disabled={!isFormDirty}
                onHide={() => onHide({ shouldUpdate: false, close: true })}
              />
            </Grid>
          </Grid>
        </form>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
