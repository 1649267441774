import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { UniversalRoll } from "../../../../../pages/configuration/models/parts/UniversalRoll";

export const getUniversalRolls = async (partId: number) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [
    { name: "PartId", value: partId }
  ];


  const resp = await apiService.callV2("[M2].[GetUniversalRolls]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as UniversalRoll[];
  } else {
    throw new Error(resp.message || "Error fetching universal rolls");
  }
};
