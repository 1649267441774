import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { DelayAreaAsset } from "../../codes/models/CatalogDelayCode";
import { DelayByShift } from "../models/DelayByShift";

type getDelaysProps = {
  startDate: Date;
  endDate: Date;
  delayAreaAssetId?: number | null;
  includeMicrodelays: boolean;
  shift?: string;
  crew?: string;
};

export const getDelays = async ({
  startDate,
  endDate,
  delayAreaAssetId,
  includeMicrodelays,
  shift,
  crew,
}: getDelaysProps) => {
  const apiService = new MESApiService();
  const parameters: spParameter[] = [];

  parameters.push({ name: "StartDate", value: startDate });
  parameters.push({ name: "EndDate", value: endDate });
  parameters.push({
    name: "DelayAreaAssetId",
    value: delayAreaAssetId || null,
  });
  parameters.push({
    name: "Shift",
    value: (shift as string) === "All" ? null : (shift as string),
  });
  parameters.push({
    name: "Crew",
    value: (crew as string) === "All" ? null : (crew as string),
  });
  parameters.push({ name: "IncludeMicrodelays", value: includeMicrodelays });

  const resp = await apiService.callV2("[DEL].[GetDelays]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as DelayByShift[];
  } else {
    throw new Error(resp.message || "Error fetching delays");
  }
};

export const getDelayAreas = async () => {
  const apiService = new MESApiService();

  const resp = await apiService.callV2("[DEL].[GetDelayAreas]", []);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as DelayAreaAsset[];
  } else {
    throw new Error(resp.message || "Error fetching delay areas");
  }
};

export const getShifts = async () => {
  const apiService = new MESApiService();

  const resp = await apiService.callV2("[MES].[GetShiftsAndCrews]", []);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as String[];
  } else {
    throw new Error(resp.message || "Error fetching shifts");
  }
};

export const getCrews = async () => {
  const apiService = new MESApiService();

  const resp = await apiService.callV2("[MES].[GetShiftsAndCrews]", []);

  if (resp.ok) {
    return get(resp, "data.tables[1].rows", []) as String[];
  } else {
    throw new Error(resp.message || "Error fetching crews");
  }
};
