import { Suspense } from "react";
import { Route } from "react-router-dom";
import { LazyLoading } from "../../controls/LazyLoading";
import PartsReport from "../../pages/report/parts";

export const ReportRoutes = [
  <Route
    path="/report-parts"
    element={
      <Suspense fallback={<LazyLoading></LazyLoading>}>
        <PartsReport />
      </Suspense>
    }
  />,
];
