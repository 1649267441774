import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FieldError } from "react-hook-form";

export const donutStatusCodes = [
  { Code: "R", Name: "Ready" },
  { Code: "U", Name: "Under Repair" },
  { Code: "D", Name: "Damaged" },
];

type Props = {
  label: string;
  value: string | null;
  onChange?: Function;
  fieldError?: FieldError | undefined;
  disabled?: boolean;
};

export const DonutStatusSelector = ({
  label,
  value,
  onChange = () => {},
  fieldError,
  disabled = false,
}: Props) => {
  const selectedStatus =
    donutStatusCodes.find((code) => code.Code === value) || null;

  return (
    <Autocomplete
      id="clear-on-escape"
      clearOnEscape
      options={donutStatusCodes}
      getOptionLabel={(option) => option.Name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          error={!!fieldError}
          helperText={fieldError?.message}
          size="small"
        />
      )}
      value={selectedStatus}
      onChange={(event, newValue) => {
        onChange(newValue?.Code ?? null);
      }}
      disabled={disabled}
    />
  );
};
