import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, LinearProgress, TextField, Typography } from "@material-ui/core";
import { GridApi } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";

import { CenteredLazyLoading } from "../../../../controls/LazyLoading";

import { NotificationSnackBar } from "../../../../controls/snackbars/notification-snackbar";
import { SectionSelector } from "../../../../shared/selectors";

import { useGridStyles } from "../../../configuration/styles/gridStyles";
import { useTableDataLeft, useTableDataRight } from "../hooks/useTableData";
import { useReadyCarriers } from "../SparePage";

export const TableSpare = () => {
  const classes = useGridStyles();

  const [currentGridApi, setCurrentGridApi] = useState<GridApi | null>(null);
  const [nextGridApi, setNextGridApi] = useState<GridApi | null>(null);
  const [currentSection, setCurrentSection] = useState<number>(0);
  const [nextSection, setNextSection] = useState<number>(0);
  const [error, setError] = useState("");
  const [message, setNotificationMessage] = useState("");

  const { columnDefsLeft } = useTableDataLeft({ setNotificationMessage });
  const { columnDefsRight } = useTableDataRight({ setNotificationMessage });

  const { data: currentCarriers, isLoading: currentLoading } = useReadyCarriers(
    {
      sectionId: currentSection,
      onError: (error: Error) => setError(error.message),
    }
  );
  const { data: nextCarriers, isLoading: nextLoading } = useReadyCarriers({
    sectionId: nextSection,
    isNextSection: true,
    onError: (error: Error) => setError(error.message),
  });

  useEffect(() => {
    if (currentLoading) {
      currentGridApi?.showLoadingOverlay();
    } else {
      currentGridApi?.hideOverlay();
    }
    if (nextLoading) {
      nextGridApi?.showLoadingOverlay();
    } else {
      nextGridApi?.hideOverlay();
    }
  }, [currentLoading, nextLoading, currentGridApi, nextGridApi]);

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
          <Typography
            variant="h5"
            style={{ margin: "15px 0 15px", fontWeight: 600 }}
          >
            Spares
          </Typography>
        </Grid>
        <Grid container>
          <Grid container md={6} direction="row">
            <Grid
              item
              md={12}
              xs={12}
              style={{ padding: "0 20px", marginBottom: 10 }}
            >
              <SectionSelector
                label="Current Section"
                value={currentSection}
                onChange={(val: number) => setCurrentSection(val)}
              />
            </Grid>
            <Grid item md={3} xs={12} style={{ padding: "0 20px" }}>
              <TextField
                label="Planned Tons"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item md={3} xs={12} style={{ padding: "0 20px" }}>
              <TextField
                label="Planned Billets"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item md={3} xs={12} style={{ padding: "0 20px" }}>
              <TextField
                label="Actual Tons"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item md={3} xs={12} style={{ padding: "0 20px" }}>
              <TextField
                label="Actual Billets"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid container alignItems="center" style={{ marginTop: 10 }}>
              <Grid item md={10} xs={12} style={{ padding: "0 20px" }}>
                <LinearProgress variant="determinate" value={5} />
              </Grid>
              <Grid item md={2} xs={12} style={{ padding: "0 20px" }}>
                <Typography>00:05:00hs</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container md={6} direction="row">
            <Grid
              item
              md={12}
              xs={12}
              style={{ padding: "0 20px", marginBottom: 10 }}
            >
              <SectionSelector
                label="Next Section"
                value={nextSection}
                onChange={(val: number) => setNextSection(val)}
              />
            </Grid>
            <Grid item md={6} xs={12} style={{ padding: "0 20px" }}>
              <TextField
                label="Planned Tons"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12} style={{ padding: "0 20px" }}>
              <TextField
                label="Planned Billets"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid container alignItems="center" style={{ marginTop: 10 }}>
              <Grid item md={10} xs={12} style={{ padding: "0 20px" }}>
                <LinearProgress variant="determinate" value={0} />
              </Grid>
              <Grid item md={2} xs={12} style={{ padding: "0 20px" }}>
                <Typography>00:00:00hs</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          className={classes.root}
          style={{ width: "100%", padding: "0 10px" }}
          spacing={2}
        >
          <Grid item md={6} xs={12}>
            <div
              style={{ position: "relative", height: "73vh", width: "100%" }}
              className={`${classes.table} ag-theme-alpine`}
            >
              <AgGridReact<any>
                rowData={currentCarriers || []}
                columnDefs={columnDefsLeft}
                rowHeight={40}
                headerHeight={34}
                animateRows
                suppressRowClickSelection
                onGridReady={(params) => setCurrentGridApi(params.api)}
                getRowId={(params) => String(params.data.StandName)}
                loadingOverlayComponent={CenteredLazyLoading}
              />
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div
              style={{ position: "relative", height: "73vh", width: "100%" }}
              className={`${classes.table} ag-theme-alpine`}
            >
              <AgGridReact<any>
                rowData={nextCarriers || []}
                columnDefs={columnDefsRight}
                rowHeight={40}
                headerHeight={34}
                animateRows
                suppressRowClickSelection
                onGridReady={(params) => setNextGridApi(params.api)}
                getRowId={(params) => String(params.data.StandName)}
                loadingOverlayComponent={CenteredLazyLoading}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
    </>
  );
};
