import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, makeStyles } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { ColDef, GridApi, ICellRendererParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import { CenteredLazyLoading } from "../../../../../../controls/LazyLoading";
import { EdgerRollChannel } from "../../../../../configuration/models/parts/EdgerRoll";
import { getEdgerRollChannels } from "../../../../repositories/parts/EdgerRollRepository";
import { FooterChannelsButtons } from "./FooterChannelsButtons";
import { StatusCheckbox } from "./StatusCheckbox";

const useStyles = makeStyles(() => ({
  table: {
    height: "41vh",
  },
}));

type useChannelsProps = {
  partId: number;
  onError: (error: Error) => void;
  onSuccess?: (data: EdgerRollChannel[]) => void;
};

export const useChannels = ({
  partId,
  onError,
  onSuccess,
}: useChannelsProps) => {
  return useQuery<EdgerRollChannel[], Error>({
    queryKey: ["channels", partId],
    queryFn: () => getEdgerRollChannels(partId),
    onError: onError,
    onSuccess: onSuccess,
    enabled: !!partId,
  });
};

type Props = {
  partId: number;
  disabled?: boolean;
};

export const ChannelsGrid = ({ partId, disabled = false }: Props) => {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const { data: channels, isLoading } = useChannels({
    partId: partId,
    onError: (error) => setError(error.message),
  });

  const columnDefs: ColDef[] = [
    {
      field: "-",
      headerName: "",
      cellRenderer: StatusCheckbox,
      cellRendererParams: (params: ICellRendererParams) => ({
        status: (params.data as EdgerRollChannel).StatusDescription,
        channelNumber: (params.data as EdgerRollChannel).ChannelNumber,
        partId: (params.data as EdgerRollChannel).EdgerRollPartId,
      }),
      flex: 1,
    },
    {
      field: "ChannelNumber",
      headerName: "Channel",
      flex: 2,
    },
    {
      field: "StatusDescription",
      headerName: "",
      flex: 2,
    },
  ];

  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, gridApi]);

  return (
    <>
      <Grid item md={12} xs={12} className={classes.table}>
        <div
          style={{ position: "relative", height: "100%", width: "100%" }}
          className="ag-theme-alpine"
        >
          <AgGridReact<EdgerRollChannel>
            rowData={channels || []}
            columnDefs={columnDefs}
            rowHeight={30}
            headerHeight={30}
            animateRows={true}
            suppressContextMenu
            loadingOverlayComponent={CenteredLazyLoading}
            onGridReady={(params) => setGridApi(params.api)}
            getRowId={(params) =>
              `${params.data.ChannelNumber}-${params.data.StatusDescription}`
            }
          />
          <div
            style={{
              position: "absolute",
              padding: 0,
              bottom: 5,
              right: 20,
            }}
          >
            <FooterChannelsButtons
              partId={partId}
              disabled={{
                upsert: channels ? channels?.length >= 5 : false,
                delete: channels ? channels?.length <= 0 : false,
              }}
            />
          </div>
        </div>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
