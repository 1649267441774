import { Theme, makeStyles } from "@material-ui/core";

export const useGridStyles = makeStyles((theme: Theme) => ({
	root: {
		"& .ag-icon-menu": {
			display: "none",
		},
		"& .ag-header-cell-label": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			fontSize: '2vh'
		},
		"& .ag-header-cell-text": {
			textAlign: "center !important",
		},

		"& .ag-checkbox-input-wrapper": {
			margin: "0 10px",
		},
		"& .ag-cell": {
			alignItems: "center",
			padding: "0 !important",
			border: "unset !important",
			textAlign: "center",
		},
		"& .ag-header-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			fontSize: 10,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		"& .ag-cell-wrapper": {
			textAlign: "center",
		},
		"& .ag-menu-option": {
			cursor: "pointer",
		},
		"& .MuiDataGrid-row": {
			cursor: "pointer",
		},
		"& .MuiDataGrid-columnHeaderTitle, & .MuiTablePagination-caption": {
			fontWeight: "bold",
			fontSize: "12px",
		},
		"& .MuiDataGrid-columnHeaderTitleContainer": {
			padding: "0",
		},
		"& .MuiDataGrid-cell--textLeft": {
			fontSize: "12px",
		}
	},
	relative: {
		position: "relative",
	},
	textfield: {
		margin: theme.spacing(2),
	},
	btnModal: {
		display: "flex",
		justifyContent: "flex-end",
	},
	dataTable: {
		padding: theme.spacing(1, 2),
	},
	table: {
		height: "61vh",
		border: "4px solid #ccc",
		borderRadius: 10,
		padding: 10,
		margin: "5px 0 0px",
	}
}));