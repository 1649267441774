import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import { Button, Grid, Typography } from "@material-ui/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { CenteredLazyLoading } from "../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../controls/snackbars/notification-snackbar";

type Props = {
  part: { Id: number; Name: string; Entity: string; QueryKey?: string };
  deleteFunction: (partId: number) => Promise<void>;
  show: boolean;
  onHide: (shouldUpdate: boolean) => void;
};

export const DeletePart = ({ part, deleteFunction, show, onHide }: Props) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (show) {
      setIsLoading(false);
    }
  }, [show]);

  const queryClient = useQueryClient();
  const deleteMutation = useMutation(deleteFunction, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        `${part.QueryKey ? part.QueryKey : part.Entity}s`,
      ]);
      setMessage(`The ${part.Entity} was deleted successfully`);
      onHide(true);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsLoading(false),
  });

  const onDelete: (data: number) => Promise<void> = async (data: number) => {
    setIsLoading(true);
    deleteMutation.mutate(part.Id);
  };

  return (
    <>
      <MesfModal
        title={`DELETE ${part.Entity.toUpperCase()}`}
        open={show}
        handleClose={() => onHide(false)}
        id={`delete-${part.Entity}-modal`}
        maxWidth="md"
      >
        <MesfModal.Content
          style={{ padding: "15px 30px" }}
          enterKeyHint={"Enter"}
        >
          {isLoading && <CenteredLazyLoading />}
          {!isLoading && (
            <>
              <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                  <Typography>
                    Are you sure you want to delete{" "}
                    <strong>{part?.Name}</strong> {part.Entity}?
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </MesfModal.Content>
        <MesfModal.Actions
          style={{ padding: "20px 30px 30px" }}
          enterKeyHint={"Enter"}
        >
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item md={3} xs={12} style={{ margin: 0 }}>
              <Button
                fullWidth
                variant="contained"
                color="default"
                onClick={() => onHide(false)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item md={3} xs={12} style={{ margin: 0 }}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => onDelete(part.Id)}
              >
                DELETE
              </Button>
            </Grid>
          </Grid>
        </MesfModal.Actions>
      </MesfModal>
      <NotificationSnackBar message={message} onHide={() => setMessage("")} />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error Deleting Profile"
      />
    </>
  );
};
