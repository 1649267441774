import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Stand } from "../models/Stand";

export const getStandsByPart = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetStandsByPart]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as Stand[];
	}
	else {
		throw new Error(resp.message || "Error fetching stands");
	}
};

export const setStandsToPart = async (partId: number, stands: Stand[]) => {
	const apiService = new MESApiService();

	const standsIds = stands.map((item) => item.StandId);
	const standIdsString = standsIds.join(",");

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId },
		{ name: "StandIds", value: standIdsString }
	];

	return await apiService.callV2("[M2].[SetStandsToPart]", parameters);
};