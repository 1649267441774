import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import { Grid, TextField } from "@material-ui/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FooterModalButtons } from "../../../../../controls/buttons/FooterModalButtons";
import { NotificationSnackBar } from "../../../../../controls/snackbars/notification-snackbar";
import { SectionGap } from "../../../models/Section";
import { upsertSectionGap } from "../../../repositories/SectionRepository";

type Props = {
  show: boolean;
  onHide: () => void;
  sectionGap: SectionGap;
};

export const SectionGapModal = ({ show, onHide, sectionGap }: Props) => {
  const [error, setError] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const { control, reset, handleSubmit, setValue } = useForm<SectionGap>({
    mode: "onChange",
  });

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(upsertSectionGap, {
    onSuccess: async () => {
      setNotificationMessage(
        `The stand ${sectionGap?.StandName} was configurated succesfully`
      );
      await queryClient.invalidateQueries(["sectionGaps"]);
      onHide();
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<SectionGap> = async (data) => {
    setIsSubmitLoading(true);
    upsertMutation.mutate(data);
  };

  const onChangeRealType = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: string) => void
  ) => {
    const value = e.target.value.replace(",", ".");
    if (value === "" || /^(\d*\.?\d{0,3}|\d+\.)$/.test(value)) {
      onChange(value);
    }
  };
  const onBlurRealType = (onChange: (value: string) => void, value: string) => {
    const numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      onChange(numValue.toFixed(3));
    } else if (value === "") {
      onChange("");
    }
  };

  useEffect(() => {
    if (show) {
      reset();
      if (sectionGap) {
        setValue("SectionId", sectionGap?.SectionId);
        setValue("StandId", sectionGap?.StandId);
        setValue("StandName", sectionGap?.StandName);
        setValue("SetpointGap", sectionGap?.SetpointGap);
        setValue("Comments", sectionGap?.Comments);
      }
    }
  }, [show, sectionGap]);

  return (
    <>
      <MesfModal
        open={show}
        handleClose={() => onHide()}
        title={`EDIT: Stand ${sectionGap?.StandName} - Gap ${
          sectionGap?.SetpointGap ? sectionGap?.SetpointGap : "-"
        }`}
        maxWidth="sm"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit(onSubmit)(e);
          }}
        >
          <MesfModal.Content dividers>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item md={6} xs={12}>
                <Controller
                  name="StandName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="Stand"
                      variant="outlined"
                      size="small"
                      value={field.value ?? ""}
                      onChange={field.onChange}
                      disabled
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  name="SetpointGap"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Setpoint Gap"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={field.value ?? ""}
                      onChange={(e) => onChangeRealType(e, field.onChange)}
                      onBlur={() =>
                        onBlurRealType(field.onChange, String(field.value))
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Controller
                  name="Comments"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="Comments"
                      variant="outlined"
                      size="small"
                      value={field.value ?? ""}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </MesfModal.Content>
          <MesfModal.Actions style={{ padding: "15px 30px" }}>
            <FooterModalButtons
              isSubmitLoading={isSubmitLoading}
              onHide={() => onHide()}
            />
          </MesfModal.Actions>
        </form>
      </MesfModal>
      <ErrorModal error={error} onHide={() => setError("")} />
      <NotificationSnackBar
        message={notificationMessage}
        onHide={() => setNotificationMessage("")}
      />
    </>
  );
};
