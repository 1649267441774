import { Part, PART_INITIAL_VALUES } from "./Part";

export interface Arbor extends Part {
	Stands: string;
	StandsId: '',
	IsActive: boolean | null;
}

export const ARBOR_INITIAL_VALUES: Arbor = {
	...PART_INITIAL_VALUES,
	Stands: '',
	StandsId: '',
	IsActive: true
};