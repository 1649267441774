import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Section } from "../models/Section";

export const getSections = async (sectionId?: number | null) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [];

  if (sectionId) parameters.push({ name: "SectionId", value: sectionId });

  const resp = await apiService.callV2("[M2].[GetSections]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as Section[];
  } else {
    throw new Error(resp.message || "Error fetching sections");
  }
};

export const getSectionsByPart = async (partId: number | null) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [{ name: "PartId", value: partId }]

  const resp = await apiService.callV2("[M2].[GetSectionsByPart]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as Section[];
  } else {
    throw new Error(resp.message || "Error fetching sections");
  }
};

export const setSectionsToPart = async (partId: number, section: Section[]) => {
	const apiService = new MESApiService();

	const standsIds = section.map((item) => item.SectionId);
	const standIdsString = standsIds.join(",");

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId },
		{ name: "SectionIds", value: standIdsString }
	];

	return await apiService.callV2("[M2].[SetSectionsToPart]", parameters);
};