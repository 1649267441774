import { getMomentTz, MESApiService, spParameter } from "@dexteel/mesf-core";
import { Asset } from "@dexteel/mesf-core/dist/models/Asset";
import { get } from "lodash-es";
import { DelayByShift } from "../models/DelayByShift";
const moment = getMomentTz();

export const getDelaysByShift = async ({
  shiftId,
  delayAreaAssetId,
  microDelays,
}: {
  shiftId: number | null;
  delayAreaAssetId: number | null;
  microDelays: boolean;
}) => {
  const apiService = new MESApiService();
  const parameters: spParameter[] = [
    { name: "ShiftId", value: shiftId || 0 },
    { name: "IncludeMicroDelays", value: microDelays },
  ];

  if (delayAreaAssetId)
    parameters.push({ name: "AssetId", value: delayAreaAssetId });

  const resp = await apiService.callV2("[DEL].[GetDelaysByShift]", parameters);
  if (resp.ok) {
    let rows = get(resp, "data.tables[0].rows", []);

    rows = rows.map((delay: DelayByShift) => ({
      ...delay,
      Start: moment.utc(delay["Start"]).toDate(),
      End: delay.End ? moment.utc(delay["End"]).toDate() : null,
    }));

    return get(resp, "data.tables[0].rows", []) as DelayByShift[];
  } else {
    throw new Error(resp.message || "Error fetching delays");
  }
};

export const getAssetsForDelayArea = async () => {
  const apiService = new MESApiService();

  const resp = await apiService.callV2("[DEL].[GetAssetsFordelayArea]", []);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as Asset[];
  } else {
    throw new Error(resp.message || "Error fetching assets");
  }
};

export const upsertDelay = async (
  DelayId: number | null,
  AssetId: number | null,
  Start: Date | null,
  End: Date | null,
  DelayCodeId: number | null,
  DelayCategoryId: number | null,
  Comments: string | null
) => {
  const apiService = new MESApiService();
  const parameters: spParameter[] = [];

  parameters.push({ name: "DelayId", value: DelayId });
  parameters.push({ name: "AssetId", value: AssetId });
  parameters.push({
    name: "Start",
    value: Start ? Start.toISOString() : null,
  });
  parameters.push({
    name: "End",
    value: End ? End.toISOString() : null,
  });
  parameters.push({ name: "DelayCodeId", value: DelayCodeId });
  parameters.push({ name: "DelayCategoryId", value: DelayCategoryId });
  parameters.push({ name: "Comments", value: Comments });

  return await apiService.callV2("[DEL].[UpsertManualDelay]", parameters);
};

export const justifyDelay = async (
  delayId: number,
  delayCodeId: number | null,
  delayCategoryId: number | null,
  comments: string | null
) => {
  const apiService = new MESApiService();
  const parameters: spParameter[] = [];

  parameters.push({ name: "DelayId", value: delayId });
  parameters.push({ name: "DelayCodeId", value: delayCodeId });
  parameters.push({ name: "DelayCategoryId", value: delayCategoryId });
  parameters.push({ name: "Comments", value: comments });

  return await apiService.callV2("[DEL].[JustifyDelay]", parameters);
};

export const getDelayCodes = async (delayAreaAssetId: number | null) => {
  const apiService = new MESApiService();
  const parameters: spParameter[] = [
    { name: "DelayAreaAssetId", value: delayAreaAssetId },
  ];
  return await apiService.callV2("[DEL].[GetDelayCodes]", parameters);
};

export const getDelayCategories = async (delayAreaAssetId: number | null) => {
  const apiService = new MESApiService();
  const parameters: spParameter[] = [];
  parameters.push({ name: "DelayAreaAssetId", value: delayAreaAssetId });
  return await apiService.callV2("[DEL].[GetDelayCategories]", parameters);
};

export const deleteDelay = async (delayId: number | null) => {
  const apiService = new MESApiService();
  const parameters: spParameter[] = [];
  parameters.push({ name: "DelayId", value: delayId });

  return await apiService.callV2("[DEL].[DeleteManualDelay]", parameters);
};

export const splitDelay = async (
  delay1: DelayByShift,
  delay2: DelayByShift
) => {
  const apiService = new MESApiService();
  const splitTime = delay1.End?.toISOString();
  const parameters: spParameter[] = [
    { name: "DelayId", value: delay1.DelayId },
    { name: "SplitTime", value: splitTime! },
    { name: "DelayCodeId1", value: delay1.DelayCodeId },
    { name: "DelayCodeId2", value: delay2.DelayCodeId },
    { name: "DelayCategoryId1", value: delay1.DelayCategoryId },
    { name: "DelayCategoryId2", value: delay2.DelayCategoryId },
    { name: "Comments1", value: delay1.Comments },
    { name: "Comments2", value: delay2.Comments },
  ];
  return await apiService.callV2("[DEL].[SplitDelay]", parameters);
};
