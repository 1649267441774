import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { GridButtons } from "../../../../../../controls/buttons/GridButtons";
import { rollPositionCodes } from "../../../../../../shared/selectors/RollPositionSelector";
import { rollStatusCodes } from "../../../../../../shared/selectors/RollStatusSelector";
import { EdgerRoll } from "../../../../models/parts/EdgerRoll";
import { RollPositionTag } from "../../../../shared/components/RollPositionTag";
import { RollStatusTag } from "../../../../shared/components/RollStatusTag";

type Props = {
  setSelectedRowData: (roll: EdgerRoll) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
  showContextMenu: Function;
};
export const useTableData = ({
  setSelectedRowData,
  setShowModal,
  showContextMenu,
}: Props) => {
  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefs: ColDef[] = [
    // Hide
    {
      field: "LocationName",
      headerName: "Location",
      hide: true,
    },
    {
      field: "Comments",
      headerName: "Comments",
      hide: true,
    },
    {
      field: "Diameter",
      headerName: "Diameter",
      hide: true,
    },
    // Visible
    {
      field: "PartName",
      headerName: "Name",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "Sections",
      headerName: "Section",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "Stands",
      headerName: "Stand",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "Diameter",
      headerName: "Diameter",
      hide: true,
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "RollStatusCode",
      headerName: "Status",
      minWidth: 20,
      maxWidth: 70,
      flex: 1,
      cellStyle: cellStyle,
      valueGetter: (params) =>
        rollStatusCodes.find(
          (code) => code.Code === (params.data as EdgerRoll).RollStatusCode
        )?.Name,
      cellRenderer: RollStatusTag,
      cellRendererParams: (params: ICellRendererParams) => ({
        status: rollStatusCodes.find(
          (code) => code.Code === (params.data as EdgerRoll).RollStatusCode
        ),
      }),
    },
    {
      field: "RollPosition",
      headerName: "Position",
      minWidth: 20,
      maxWidth: 70,
      flex: 1,
      cellStyle: cellStyle,
      valueGetter: (params) =>
        rollPositionCodes.find(
          (code) => code.Code === (params.data as EdgerRoll).RollPosition
        )?.Name,
      cellRenderer: RollPositionTag,
      cellRendererParams: (params: ICellRendererParams) => ({
        position: rollPositionCodes.find(
          (code) => code.Code === (params.data as EdgerRoll).RollPosition
        ),
      }),
    },
    {
      field: "-",
      cellRenderer: GridButtons,
      cellRendererParams: (params: ICellRendererParams) => ({
        setId: setSelectedRowData,
        id: params.data as EdgerRoll,
        setShowModal: setShowModal,
        edit: false,
        showContextMenu: () =>
          showContextMenu(event, params.data, "edgerRollContext"),
      }),
      wrapText: false,
      autoHeight: false,
      wrapHeaderText: false,
      headerName: "",
      sortable: false,
      flex: 1,
      maxWidth: 180,
      filter: false,
      autoHeaderHeight: true,
    },
  ];
  return { columnDefs };
};
