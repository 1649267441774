import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Rollset, RollsetRolls } from "../models/Rollset";

export const getRollsets = async (rollsetId?: number | null) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [];

  if (rollsetId) parameters.push({ name: "RollsetId", value: rollsetId });

  const resp = await apiService.callV2("[M2].[GetRollsets]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as Rollset[];
  } else {
    throw new Error(resp.message || "Error fetching rollsets");
  }
};

export const upsertRollset = async ({
  rollset,
  TopRollId,
  BottomRollId
}: {
  rollset: Rollset;
  TopRollId: number;
  BottomRollId: number;
}) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [
    { name: "RollsetId", value: rollset.RollsetId },
    { name: "TopRollId", value: TopRollId },
    { name: "BottomRollId", value: BottomRollId },
    { name: "Comments", value: rollset.Comments },
  ];

  const resp = await apiService.callV2("[M2].[UpsertRollsetV2]", parameters);

  if (resp.ok) {
    return get(resp, "data.returnValue", null) as number;
  } else {
    throw new Error(resp.message || "Error upserting rollset");
  }
};

export const deleteRollset = async (rollsetId: number) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [{ name: "RollsetId", value: rollsetId }];

  const resp = await apiService.callV2("[M2].[DeleteRollset]", parameters);

  if (!resp.ok) {
    throw new Error(resp.message || "Error deleting rollset");
  }
};

export const upsertRollsetRolls = async (rollset: RollsetRolls) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [
    { name: "RollsetId", value: rollset.RollsetId },
    { name: "PartId", value: rollset.PartId ?? null },
    {
      name: "IsDefaultTopRoll",
      value: rollset.IsDefaultTopRoll ? true : false,
    },
    {
      name: "IsDefaultBottomRoll",
      value: rollset.IsDefaultBottomRoll ? true : false,
    },
  ];

  const resp = await apiService.callV2("[M2].[UpsertRollsetRoll]", parameters);

  if (!resp.ok) {
    throw new Error(resp.message || "Error upserting roll of rollset");
  }
};

export const getRollsetRolls = async (rollsetId?: number | null) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [
    { name: "RollsetId", value: rollsetId ?? 0 },
  ];

  const resp = await apiService.callV2("[M2].[GetRollsetRolls]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as RollsetRolls[];
  } else {
    throw new Error(resp.message || "Error fetching rollset rolls");
  }
};
