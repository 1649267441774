import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { GridButtons } from "../../../../../../controls/buttons/GridButtons";
import { donutStatusCodes } from "../../../../../../shared/selectors/DonutStatusSelector";
import { Donut } from "../../../../models/parts/Donut";
import { DonutStatusTag } from "../components/DonutStatusTag";

type Props = {
  setSelectedRowData: (donut: Donut) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
  showContextMenu: Function;
};
export const useTableData = ({
  setSelectedRowData,
  setShowModal,
  showContextMenu,
}: Props) => {
  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefs: ColDef[] = [
    // Hide
    {
      field: "LocationName",
      headerName: "Location",
      hide: true,
    },
    // Visible
    {
      field: "PartName",
      headerName: "Name",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "Sections",
      headerName: "Section",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "Stands",
      headerName: "Stand",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "Diameter",
      headerName: "Diameter",
      maxWidth: 80,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "DonutStatusCode",
      headerName: "Status",
      minWidth: 20,
      maxWidth: 70,
      flex: 1,
      cellStyle: cellStyle,
      valueGetter: (params) =>
        donutStatusCodes.find(
          (code) => code.Code === (params.data as Donut).DonutStatusCode
        )?.Name,
      cellRenderer: DonutStatusTag,
      cellRendererParams: (params: ICellRendererParams) => ({
        status: donutStatusCodes.find(
          (code) => code.Code === (params.data as Donut).DonutStatusCode
        ),
      }),
    },
    {
      field: "-",
      cellRenderer: GridButtons,
      cellRendererParams: (params: ICellRendererParams) => ({
        setId: setSelectedRowData,
        id: params.data as Donut,
        edit: false,
        setShowModal: setShowModal,
        showContextMenu: () =>
          showContextMenu(event, params.data, "donutContext"),
      }),
      wrapText: false,
      autoHeight: false,
      wrapHeaderText: false,
      headerName: "",
      sortable: false,
      flex: 1,
      maxWidth: 180,
      filter: false,
      autoHeaderHeight: true,
    },
  ];
  return { columnDefs };
};
