import { Part, PART_INITIAL_VALUES } from "./Part";

export interface EdgerRoll extends Part {
	RollPosition: string | null;
	RollStatusCode: string | null;
	RollTypeCode: string | null;
	Diameter: number | null;
	NewDiameter: number | null;
	ScrapDiameter: number | null;
	Sections: string;
	Stands: string;
	ChannelsStatus: string;
	IsActive: boolean | null;
}

export const EDGER_ROLL_INITIAL_VALUES: EdgerRoll = {
	...PART_INITIAL_VALUES,
	RollPosition: 'B',
	RollStatusCode: 'R',
	RollTypeCode: 'E',
	Diameter: null,
	NewDiameter: null,
	ScrapDiameter: null,
	Sections: '',
	Stands: '',
	ChannelsStatus: '',
	IsActive: true
};

export interface EdgerRollChannel {
	EdgerRollPartId: number | null;
	PartName: string | null;
	ChannelNumber: number | null;
	ChannelStatusCode: string | null;
	StatusDescription: string | null;
	RollStatusCode: string | null;
	RollStatusDescription: string | null;
	Diameter: number | null;
	IsActive: boolean | null;
}