import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, makeStyles } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { ColDef, GridApi, SelectionChangedEvent } from "ag-grid-community";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { isNil } from "lodash-es";
import { useEffect, useState } from "react";
import { CenteredLazyLoading } from "../../../../controls/LazyLoading";
import { Stand } from "../../models/Stand";
import { getStandsByPart } from "../../repositories/StandRepository";

const useStyles = makeStyles(() => ({
  table: {
    height: "41vh",
  },
}));

type standsByPartsProps = {
  partId: number;
  onError: (error: Error) => void;
  onSuccess: (data: Stand[]) => void;
};

export const useStandsByPart = ({ partId, onError, onSuccess }: standsByPartsProps) => {
  return useQuery<Stand[], Error>({
    queryKey: ["standsByPart", partId],
    queryFn: () => getStandsByPart(partId),
    onError: onError,
    onSuccess: onSuccess,
    enabled: !!partId
  });
};

export const useStands = (onError: (error: Error) => void) => {
  return useQuery<Stand[], Error>({
    queryKey: ["stands"],
    queryFn: () => getStandsByPart(),
    onError: onError,
  });
};

type Props = {
  onSelectionChanged: Function;
  disabled?: boolean;
  standsOfPart: Stand[];
};

export const StandGrid = ({
  onSelectionChanged,
  disabled = false,
  standsOfPart,
}: Props) => {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const { data: stands, isLoading } = useStands((error) =>
    setError(error.message)
  );

  const onAgGridSelectionChanged = (event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows();
    onSelectionChanged(selectedRows);
  };

  const columnDefs: ColDef[] = [
    {
      field: "StandName",
      headerName: "Applies to Stands",
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      headerCheckboxSelection: true,
      flex: 1,
    },
  ];

  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, gridApi]);

  useEffect(() => {
    if (gridApi) {
      gridApi.forEachNode((node) => {
        node.setSelected(
          !!node.data &&
            !isNil(
              standsOfPart.find(
                (p) => p.StandId === (node.data as Stand).StandId
              )
            )
        );
      });
    }
  }, [standsOfPart, gridApi]);

  return (
    <>
      <Grid item md={12} xs={12} className={classes.table}>
        <div
          style={{ height: "100%", width: "100%" }}
          className="ag-theme-alpine"
        >
          <AgGridReact<Stand>
            rowData={stands || []}
            columnDefs={columnDefs}
            rowHeight={22}
            headerHeight={30}
            animateRows={true}
            rowSelection="multiple"
            rowMultiSelectWithClick
            suppressContextMenu
            loadingOverlayComponent={CenteredLazyLoading}
            onSelectionChanged={onAgGridSelectionChanged}
            onGridReady={(params) => setGridApi(params.api)}
            getRowId={(params) => String(params.data.StandId)}
          />
        </div>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
