import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Donut } from "../models/Donut";

export const getDonuts = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetDonuts]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as Donut[];
	}
	else {
		throw new Error(resp.message || "Error fetching donuts");
	}
};