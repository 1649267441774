import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Section } from "../../models/Section";
import { getSections } from "../../repositories/SectionRepository";
import { usePageStyles } from "../../styles/pageStyles";
import { TableSection } from "./components/TableSection";

export const useSections = (
  sectionId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<Section[], Error>({
    queryKey: ["sections", sectionId],
    queryFn: () => getSections(sectionId),
    onError: onError,
  });
};

export const SectionPage = () => {
  const classes = usePageStyles();

  const { sectionId } = useParams();

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableSection sectionId={sectionId} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
