import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, Paper, Typography } from "@material-ui/core";
import { GridApi, RowClassParams, RowStyle } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";

import { TextFieldSearchFilter } from "../../../../shared/components/TextFieldSearchFilter";
import { useTableData } from "../hooks/useTableData";
import { Part } from "../models/Part";
import { useParts } from "../PartsReportPage";
import { useGridStyles } from "../styles/gridStyles";
import { PartDetails } from "./PartDetails";

export const partTypeCodes = [
  { Code: "U", Name: "Universal Roll", Color: "#fdedec" },
  { Code: "E", Name: "Edger Roll", Color: "#f5eef8" },
  { Code: "H", Name: "Carrier", Color: "#eaf2f8" },
  { Code: "C", Name: "Chock", Color: "#e8f8f5" },
  { Code: "G", Name: "Guide", Color: "#e9f7ef" },
  { Code: "A", Name: "Arbor", Color: "#fef9e7" },
  { Code: "T", Name: "Cassette", Color: "#fef5e7" },
  { Code: "K", Name: "Cassette Chock", Color: "#fbeee6" },
  { Code: "D", Name: "Donut", Color: "#eaecee" },
];

export const TableParts = () => {
  const classes = useGridStyles();

  const [filterValue, setFilterValue] = useState("");
  const [selectedRowData, setSelectedRowData] = useState<Part | null>(null);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [showDetails, setShowDetails] = useState<
    "U" | "E" | "H" | "C" | "G" | "A" | "T" | "K" | "D" | ""
  >("");
  const [error, setError] = useState("");

  const { data: rows, isLoading } = useParts({
    onError: (error) => setError(error.message),
  });

  const getRowStyle = (
    params: RowClassParams<any, any>
  ): RowStyle | undefined => {
    const type =
      partTypeCodes.find((type) => type.Code === params.data.PartTypeCode) ||
      null;
    return { background: type ? type?.Color : "" };
  };

  const { columnDefs } = useTableData();

  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, gridApi]);

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            Parts Report
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} style={{ margin: "10px 15px" }}>
          <TextFieldSearchFilter
            value={filterValue}
            onChange={(val) => {
              setFilterValue(val);
              gridApi?.setQuickFilter(val);
            }}
          />
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ padding: "0 15px" }}
          className={classes.root}
        >
          <Grid item xs={12} md={6}>
            <Paper elevation={4} style={{ padding: 6 }}>
              <div
                style={{ height: "67vh", padding: 5 }}
                className="ag-theme-alpine"
              >
                <AgGridReact
                  rowData={rows}
                  columnDefs={columnDefs}
                  rowHeight={34}
                  headerHeight={34}
                  animateRows
                  rowSelection="single"
                  suppressContextMenu
                  onGridReady={(params) => setGridApi(params.api)}
                  getRowStyle={getRowStyle}
                  onRowSelected={(event) => {
                    if (event.node.isSelected()) {
                      setSelectedRowData(event.data);
                      setShowDetails(event.data.PartTypeCode);
                    }
                  }}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={4} style={{ padding: 6 }}>
              <div style={{ height: "67vh", padding: 5 }}>
                <PartDetails showDetails={showDetails} part={selectedRowData} />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
