import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Cassette } from "../../../models/parts/Cassette";
import { getCassettes } from "../../../repositories/parts/CassetteRepository";
import { usePageStyles } from "../../../styles/pageStyles";
import { TableCassette } from "./components/TableCassette";

export const useCassettes = (
  partId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<Cassette[], Error>({
    queryKey: ["cassettes", partId],
    queryFn: () => getCassettes(partId),
    onError: onError,
  });
};

export const CassettePage = () => {
  const classes = usePageStyles();

  const { partId } = useParams();

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableCassette partId={partId} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
