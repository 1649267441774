import { Part, PART_INITIAL_VALUES } from "./Part";

export interface CassetteChock extends Part {
	RebuildTimestamp: Date | null;
	BearingChangeTimestamp: Date | null;
	IsActive: boolean | null;
}

export const CASSETTE_CHOCK_INITIAL_VALUES: CassetteChock = {
	...PART_INITIAL_VALUES,
	RebuildTimestamp: null,
	BearingChangeTimestamp: null,
	IsActive: true
};