import { Part, PART_INITIAL_VALUES } from "./Part";

export interface Guide extends Part {
	GuideTypeCode: string | null;
	Sections: string;
	Stands: string;
	IsActive: boolean | null;
}

export const GUIDE_INITIAL_VALUES: Guide = {
	...PART_INITIAL_VALUES,
	GuideTypeCode: null,
	Sections: '',
	Stands: '',
	IsActive: true
};