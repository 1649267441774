import { ErrorModal } from "@dexteel/mesf-core";
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmationDialogRaw } from "../../controls/modals/ConfirmationDialogRaw";
import {
  autocompleteOptionColor,
  useAutocompleteOptionStyles,
} from "../../utils/theme";
import { Donut } from "../models/Donut";
import { getDonuts } from "../repositories/DonutRepository";

type Props = {
  label: string;
  value: number | null;
  onChange?: Function;
  disabled?: boolean;
  onForceAssembly?: (force: boolean) => void;
};

const useDonuts = (partId?: number, onError?: (error: Error) => void) => {
  return useQuery<Donut[], Error>({
    queryKey: ["donuts", partId],
    queryFn: () => getDonuts(partId),
    onError: onError,
  });
};

export const DonutSelector = ({
  label,
  value,
  onChange = () => {},
  disabled = false,
  onForceAssembly = () => {},
}: Props) => {
  const classes = useAutocompleteOptionStyles();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [selectedValue, setSelectedValue] = useState<Donut | null>(null);

  const { data: donuts, isLoading } = useDonuts(undefined, (error) =>
    setError(error.message)
  );

  const selectedDonut = donuts?.find((donut) => donut.PartId === value) || null;

  const inUse = (props: Donut | null) => {
    if (props?.InUse) return true;
    return false;
  };

  const handleConfirmationClose = (result?: string) => {
    setMessage("");
    if (result === "OK") {
      onForceAssembly(true);
      onChange(selectedValue?.PartId ?? null);
      setSelectedValue(null);
      return;
    }
  };

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={donuts || []}
        getOptionLabel={(option) => option.PartName}
        renderOption={(props, option) => {
          return (
            <Box
              bgcolor={inUse(props) ? autocompleteOptionColor : ""}
              component="li"
              className={classes.optionBox}
            >
              <span>
                <Typography>
                  {props.PartName}
                  <i>
                    {inUse(props)
                      ? props?.PartId == value
                        ? " (Actual) "
                        : " (In Use)"
                      : ""}
                  </i>
                </Typography>
              </span>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "8px",
                  marginTop: 7,
                }}
              >
                <Chip
                  size="small"
                  color="primary"
                  variant="outlined"
                  label={`Stands: ${props?.Stands ?? "-"}`}
                />
                <Chip
                  size="small"
                  color="secondary"
                  variant="outlined"
                  label={`Sections: ${props?.Sections ?? "-"}`}
                />
              </div>
            </Box>
          );
        }}
        getOptionSelected={(option, value) => option.PartId === value.PartId}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <>
                      {params.InputProps.endAdornment}
                      {value ? (
                        <IconButton
                          onClick={() =>
                            navigate(`/configuration/donuts/${value}`)
                          }
                          size="small"
                          title="See in donuts"
                        >
                          <VisibilityIcon />
                        </IconButton>
                      ) : null}
                    </>
                  )}
                </>
              ),
            }}
          />
        )}
        value={selectedDonut}
        onChange={(event, newValue) => {
          if (inUse(newValue)) {
            setMessage(
              `The donut ${newValue?.PartName} is already in use by another roll, do you want to disassemble it?`
            );
            setSelectedValue(newValue);
          } else onChange(newValue?.PartId ?? null);
        }}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading donuts"
      />
      <ConfirmationDialogRaw
        title="CONFIRMATION"
        message={message}
        open={message !== ""}
        onClose={handleConfirmationClose}
      />
    </>
  );
};
