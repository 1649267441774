import { ErrorModal, getMomentTz } from "@dexteel/mesf-core";
import {
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Moment } from "moment-timezone";
import { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { NotificationSnackBar } from "../../../controls/snackbars/notification-snackbar";
import { DelaysParetoGraphic } from "./components/DelaysParetoGraphic";
import { Filters } from "./components/filters/Filters";
import { DelayForPareto } from "./models/DelayForPareto";
import { getDelaysForPareto } from "./repositories/DelaysParetoReportRepository";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "15px 20px 10px",
    borderRadius: "0.75rem",
    height: "91vh",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  filterContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: theme.spacing(2),
  },
  filterGroup: {
    display: "flex",
    alignItems: "flex-end",
  },
  buttonGroup: {
    display: "flex",
    gap: theme.spacing(2),
  },
  button: {
    whiteSpace: "nowrap",
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
    width: "100%",
  },
  tableContainer: {
    width: "100%",
    height: "calc(100% - 20px)",
    padding: "2px",
    "& .ag-root-wrapper": {
      border: "none",
    },
    "& .ag-header": {
      borderTop: "1px solid #e0e0e0",
    },
  },
}));

const moment = getMomentTz();

type useDelaysParetoProps = {
  startDate: Date;
  endDate: Date;
  delayAreaAssetId?: number | null;
  onError: (error: Error) => void;
};

export const useDelaysPareto = ({
  startDate,
  endDate,
  delayAreaAssetId,
  onError,
}: useDelaysParetoProps) => {
  return useQuery<DelayForPareto[], Error>({
    queryKey: ["delaysPareto", startDate, endDate, delayAreaAssetId],
    queryFn: () =>
      getDelaysForPareto({
        startDate,
        endDate,
        delayAreaAssetId,
      }),
    onError: onError,
  });
};

export const DelaysParetoReportPage = () => {
  const classes = useStyles();

  const [error, setError] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");

  const [filterStartDate, setFilterStartDate] = useState<Moment>(
    moment().startOf("day")
  );
  const [filterEndDate, setFilterEndDate] = useState<Moment>(
    moment().endOf("day")
  );
  const [selectedDelayArea, setSelectedDelayArea] = useState<number | null>(
    null
  );

  const { data: delaysPareto } = useDelaysPareto({
    startDate: filterStartDate.toDate(),
    endDate: filterEndDate.toDate(),
    delayAreaAssetId: selectedDelayArea,
    onError: (e) => setError(e.message),
  });

  const handleFilterChange = (startDate: Moment, endDate: Moment) => {
    setFilterStartDate(startDate);
    setFilterEndDate(endDate);
  };

  const handleDelayAreaChange = (assetId: number | null) => {
    setSelectedDelayArea(assetId);
  };

  return (
    <>
      <Paper elevation={1} className={classes.root}>
        <Typography
          variant="h5"
          className={classes.header}
          style={{ fontWeight: 600 }}
        >
          Pareto Report
        </Typography>
        <Grid container>
          <Grid item md={10}>
            <div className={classes.filterGroup}>
              <Filters
                onFilterChange={handleFilterChange}
                initialStartDate={filterStartDate}
                initialEndDate={filterEndDate}
                selectedDelayArea={selectedDelayArea}
                onDelayAreaChange={handleDelayAreaChange}
              />
            </div>
          </Grid>
        </Grid>
        <Divider style={{ marginBottom: 10, marginTop: 10 }} />
        <DelaysParetoGraphic delaysForPareto={delaysPareto || []} />
      </Paper>
      <ErrorModal
        error={error}
        onHide={() => {
          setError("");
        }}
      />
      <NotificationSnackBar
        message={notificationMessage}
        onHide={() => setNotificationMessage("")}
      />
    </>
  );
};
