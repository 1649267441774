import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { CassetteChock } from "../models/CassetteChock";

export const getCassetteChocks = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetCassetteChocks]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as CassetteChock[];
	}
	else {
		throw new Error(resp.message || "Error fetching cassette chocks");
	}
};