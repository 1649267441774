import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Section, SectionGap } from "../models/Section";

export const getSections = async (sectionId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (sectionId)
		parameters.push({ name: "SectionId", value: sectionId });

	const resp = await apiService.callV2("[M2].[GetSections]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as Section[];
	}
	else {
		throw new Error(resp.message || "Error fetching sections");
	}
};
export const upsertSection = async (section: Section) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "SectionId", value: section.SectionId },
		{ name: "SectionName", value: section.SectionName },
		{ name: "SectionFamilyId", value: section.SectionFamilyId }
	];

	const resp = await apiService.callV2("[M2].[UpsertSection]", parameters);

	if (resp.ok){
		return get(resp, "data.returnValue", null) as number;
	}
	else {
		throw new Error(resp.message || "Error upserting section");
	}
};

export const deleteSection = async (sectionId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "SectionId", value: sectionId }
	];

	const resp = await apiService.callV2("[M2].[DeleteSection]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error deleting section");
	}
};

export const getSectionGaps = async (sectionId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "SectionId", value: sectionId ?? 0 }
	];

	const resp = await apiService.callV2("[M2].[GetSectionGaps]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as SectionGap[];
	}
	else {
		throw new Error(resp.message || "Error fetching section gaps");
	}
};

export const upsertSectionGap = async (sectionGap: SectionGap) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "SectionId", value: sectionGap.SectionId },
		{ name: "StandId", value: sectionGap.StandId },
		{ name: "SetpointGap", value: Number(sectionGap.SetpointGap) },
		{ name: "Comments", value: sectionGap.Comments },
	];

	const resp = await apiService.callV2("[M2].[UpsertSectionGap]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error fetching section gaps");
	}
};