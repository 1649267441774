import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { EdgerRoll, EdgerRollChannel } from "../../models/parts/EdgerRoll";

export const getEdgerRolls = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

const resp = await apiService.callV2("[M2].[GetEdgerRolls]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as EdgerRoll[];
	}
	else {
		throw new Error(resp.message || "Error fetching arbors");
	}
};
export const upsertEdgerRoll = async (roll: EdgerRoll) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: roll.PartId },
		{ name: "PartName", value: roll.PartName },
		{ name: "LocationId", value: roll.LocationId },
		{ name: "Comments", value: roll.Comments },
		{ name: "RollPosition", value: roll.RollPosition },
		{ name: "RollStatusCode", value: roll.RollStatusCode },
		{ name: "Diameter", value: roll.Diameter },
		{ name: "NewDiameter", value: roll.NewDiameter },
		{ name: "ScrapDiameter", value: roll.ScrapDiameter },
	];

	const resp = await apiService.callV2("[M2].[UpsertEdgerRoll]", parameters);

	if (resp.ok){
		return get(resp, "data.returnValue", null) as number;
	}
	else {
		throw new Error(resp.message || "Error upserting roll");
	}
};

export const deleteEdgerRoll = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	const resp = await apiService.callV2("[M2].[DeleteRoll]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error deleting roll");
	}
};

export const getEdgerRollChannels = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetEdgerRollChannels]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as EdgerRollChannel[];
	}
	else {
		throw new Error(resp.message || "Error fetching arbors");
	}
};

export const upsertEdgerRollChannel = async ({
	status,
	channelNumber,
	partId,
  }: {
	status?: string;
	channelNumber?: number;
	partId: number;
}) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [{ name: "PartId", value: partId }];

	if (channelNumber)
		parameters.push({ name: "ChannelNumber", value: channelNumber });		
	if (status)
		parameters.push({ name: "ChannelStatusCode", value: status });		

	const resp = await apiService.callV2("[M2].[UpsertEdgerRollChannel]", parameters);

	if (resp.ok){
		return get(resp, "data.returnValue", null) as number;
	}
	else {
		throw new Error(resp.message || "Error upserting edger roll channel");
	}
};

export const deleteEdgerRollChannel = async ({
	partId
  }: {
	partId: number;
}) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [{ name: "PartId", value: partId }];	

	const resp = await apiService.callV2("[M2].[DeleteEdgerRollChannel]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error deleting edger roll channel");
	}
};