import { MenuOptionType } from "@dexteel/mesf-core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ContentCopyIcon from "@material-ui/icons/FileCopy";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";

import { Guide } from "../../../../models/parts/Guide";

type Props = {
  setSelectedRowData: (guide: Guide) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
};
export const useGuideOptionsFunctions = ({
  setSelectedRowData,
  setShowModal,
}: Props) => {
  const getMenuOptions = (data: Guide) => {
    let options: MenuOptionType[] = [];
    options = options.concat([
      {
        name: "New Guide",
        key: "new_guide",
        onClick: () => {
          setShowModal("create");
        },
        icon: <PlaylistAddIcon />,
      },
    ]);
    if (data) {
      options = options.concat([
        {
          name: "Edit Guide",
          key: "edit_guide",
          onClick: () => {
            setSelectedRowData(data);
            setShowModal("update");
          },
          icon: <EditIcon />,
        },
        {
          name: "Delete Guide",
          key: "delete_guide",
          onClick: () => {
            setSelectedRowData(data);
            setShowModal("delete");
          },
          icon: <DeleteIcon />,
        },
        {
          name: "Duplicate Guide",
          key: "duplicate_guide",
          onClick: () => {
            setSelectedRowData({
              ...data,
              PartName: `${data.PartName} (Copy)`,
              RFIDTag: null,
              IsDuplicate: true,
            });
            setShowModal("create");
          },
          icon: <ContentCopyIcon />,
        },
      ]);
    }
    return options;
  };
  return {
    getMenuOptions,
  };
};
