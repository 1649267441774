import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogLocation } from "../models/CatalogLocation";

export const getLocations = async (locationId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (locationId)
		parameters.push({ name: "LocationId", value: locationId });

	return await apiService.callV2("[M2].[GetLocations]", parameters);
};
export const upsertLocation = async (location: CatalogLocation) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "LocationId", value: location.LocationId },
		{ name: "ParentLocationId", value: location.ParentLocationId },
		{ name: "LocationName", value: location.LocationName },
		{ name: "LocationTypeCode", value: location.LocationTypeCode },
		{ name: "IsExclusive", value: location.IsExclusive },
	];

	return await apiService.callV2("[M2].[UpsertLocation]", parameters);
};
export const updateParentLocation = async (locationIds: (number | string)[], parentLocationId: number) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "LocationIds", value: locationIds.join(",") },
		{ name: "ParentLocationId", value: parentLocationId }
	];

	return await apiService.callV2("[M2].[UpdateParentLocationId]", parameters);
};

export const deleteLocation = async (locationId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "LocationId", value: locationId }
	];

	return await apiService.callV2("[M2].[DeleteLocation]", parameters);
};