import {
  ErrorModal,
  ShiftDayNavigatorControl,
  useShiftNavigator,
} from "@dexteel/mesf-core";
import { Asset } from "@dexteel/mesf-core/dist/models/Asset";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { LazyBackDropLoading } from "../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../controls/snackbars/notification-snackbar";
import { DelayAreaFilter } from "../shared/components/DelayAreaFilter";
import { DelaysManagerTable } from "./components/table/DelaysManagerTable";
import { useDelaysManagerContext } from "./context/DelaysManagerContext";
import { DelayByShift } from "./models/DelayByShift";
import {
  getAssetsForDelayArea,
  getDelaysByShift,
} from "./repositories/DelaysManagerRepository";

type useDelaysByShiftProps = {
  shiftId: number | undefined;
  delayAreaAssetId: number | null;
  showMicroDelays: boolean;
  onError: (error: Error) => void;
};

export const useDelaysByShift = ({
  shiftId,
  delayAreaAssetId,
  showMicroDelays = false,
  onError,
}: useDelaysByShiftProps) => {
  return useQuery<DelayByShift[], Error>({
    queryKey: ["delays", shiftId, delayAreaAssetId, showMicroDelays],
    queryFn: () =>
      getDelaysByShift({
        shiftId: shiftId || null,
        delayAreaAssetId: delayAreaAssetId,
        microDelays: showMicroDelays,
      }),
    onError: onError,
  });
};

export const useAssetsForDelayAreas = (onError: (error: Error) => void) => {
  return useQuery<Asset[], Error>({
    queryKey: ["assets"],
    queryFn: () => getAssetsForDelayArea(),
    onError: onError,
  });
};

type Props = {};

export const DelaysManagerPage = (props: Props) => {
  const { state, actions } = useDelaysManagerContext();

  const [error, setError] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showMicroDelays, setShowMicroDelays] = useState(false);
  const [selectedDelayArea, setSelectedDelayArea] = useState<number | null>(
    null
  );

  const navigate = useNavigate();
  const { shiftId: shiftIdParam } = useParams();
  const [params] = useSearchParams();
  const { shiftInfo, getShiftDataFromAPI } = useShiftNavigator();

  const {
    data: delays,
    refetch,
    isLoading,
  } = useDelaysByShift({
    shiftId: shiftInfo?.CurrentShiftId,
    delayAreaAssetId: selectedDelayArea,
    showMicroDelays: showMicroDelays,
    onError: (e) => setError(e.message),
  });

  useEffect(() => {
    if (shiftInfo) {
      const shiftId = get(shiftInfo, "CurrentShiftId", null);
      if (state.delaySelected && state.delaySelected !== 0) {
        navigate(`/delays-manager/${shiftId}?delayId=${state.delaySelected}`);
      } else navigate(`/delays-manager/${shiftId}`);
      (async () => {
        await refetch();
      })();
    }
  }, [shiftInfo, selectedDelayArea, showMicroDelays]);

  useEffect(() => {
    if (shiftIdParam) {
      (async () => {
        const shiftId = Number(shiftIdParam);
        await getShiftDataFromAPI(null, shiftId);
      })();
    } else {
      (async () => {
        await getShiftDataFromAPI(null, 0);
      })();
    }
    const delayId = params.get("delayId");
    if (delayId) {
      actions.setDelaySelected(Number(delayId));
    }
  }, []);

  return (
    <>
      <Paper
        elevation={1}
        style={{
          width: "100%",
          padding: "15px 20px 10px",
          borderRadius: "0.75rem",
          height: "91vh",
        }}
      >
        <LazyBackDropLoading open={state.isLoadingBackground} />
        <Grid container justifyContent="center" alignItems="center">
          <Grid item md={12} xs={12} style={{ margin: 0 }}>
            <Grid item md={12} xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h5" style={{ fontWeight: 600 }}>
                  Delays Manager
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item style={{ width: "fit-content" }}>
                  <ShiftDayNavigatorControl preventInit />
                </Grid>
                <Grid item md={2}>
                  <DelayAreaFilter
                    value={selectedDelayArea}
                    onChange={(assetId) => {
                      setSelectedDelayArea(assetId);
                    }}
                    height={49}
                  />
                </Grid>
                <Grid item xs={4} style={{ margin: 0, padding: "15px" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showMicroDelays}
                          color="primary"
                          onChange={(event) =>
                            setShowMicroDelays(event.target.checked)
                          }
                        />
                      }
                      label="Show microdelays"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
            <Divider style={{ marginBottom: 10 }} />
            <DelaysManagerTable
              loadingGrid={isLoading}
              rows={delays || []}
              refreshData={refetch}
            />
          </Grid>
        </Grid>
      </Paper>
      <ErrorModal error={error} onHide={() => setError("")} />
      <NotificationSnackBar
        message={notificationMessage}
        onHide={() => setNotificationMessage("")}
      />
    </>
  );
};
