import { Chip, Tooltip } from "@material-ui/core";
import { ColDef, ColGroupDef, ICellRendererParams } from "ag-grid-enterprise";
import { useNavigate } from "react-router-dom";
import { SpareCarrier } from "../../models/SpareCarrier";
import {
  actionRequiredCodes,
  ActionRequiredTag,
} from "../components/ActionRequiredTag";
import { CommentsColumn } from "../components/comment/CommentsColumn";

const CarrierChip = ({
  carrier,
}: {
  carrier: { status: string; name: string; id: number | null };
}) => {
  const colors = [
    { Code: "R", Name: "Ready", Color: "#00D188" },
    { Code: "A", Name: "Assembled In Progress", Color: "#FAD215" },
  ];

  const backgroundColor = colors.find((c) => c.Code === carrier.status)?.Color;
  const navigate = useNavigate();

  return (
    <Tooltip
      title={colors.find((c) => c.Code === carrier.status)?.Name ?? ""}
      onClick={() => {
        navigate(`/carriers-assembly/${carrier.id}`);
      }}
    >
      <Chip
        style={{ backgroundColor, color: "black" }}
        label={carrier.name}
        size="small"
      />
    </Tooltip>
  );
};

// In the future, the next section will have these chip
/* const CarrierCandidateChip = ({
  carrier,
}: {
  carrier: { status: string; name: string };
}) => {
  const colors = [
    { Code: "N", Name: "None", Color: "#00D188" },
    { Code: "S", Name: "Soft Change", Color: "#FAD215" },
    { Code: "F", Name: "Full Change", Color: "#F09EA2" },
  ];

  const backgroundColor = colors.find((c) => c.Code === carrier.status)?.Color;

  return (
    <Chip
      style={{ backgroundColor, color: "black" }}
      label={carrier.name}
      size="small"
    />
  );
}; */

export const useTableDataLeft = ({
  setNotificationMessage,
}: {
  setNotificationMessage: (message: string) => void;
}) => {
  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefsLeft: (ColDef | ColGroupDef)[] = [
    {
      field: "StandName",
      headerName: "Stand",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "PartName",
      headerName: "Carrier",
      cellRenderer: (params: ICellRendererParams) => {
        return (params.data as SpareCarrier).PartName ? (
          <CarrierChip
            carrier={{
              name: (params.data as SpareCarrier).PartName ?? "",
              status: (params.data as SpareCarrier).Status ?? "",
              id: (params.data as SpareCarrier).PartId!,
            }}
          />
        ) : null;
      },
      minWidth: 20,
      flex: 2,
      cellStyle: cellStyle,
    },
    {
      field: "Spares",
      headerName: "Spares",
      minWidth: 20,
      flex: 2,
      cellStyle: cellStyle,
      cellRenderer: (params: ICellRendererParams) => {
        const spares = (params.data as SpareCarrier)?.SparesFormatted || [];

        return spares.length > 0 ? (
          <div style={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
            {spares?.map(
              (spare: { status: string; name: string; id: number | null }) => (
                <CarrierChip carrier={spare} />
              )
            )}
          </div>
        ) : null;
      },
      tooltipValueGetter: (params) =>
        (params.data as SpareCarrier)?.Spares ?? "",
    },
    {
      field: "OtherComments",
      headerName: "Carriers Details",
      minWidth: 20,
      flex: 2,
      cellStyle: cellStyle,
    },
    {
      field: "Comments",
      headerName: "Comments",
      cellRenderer: (params: any) => {
        return CommentsColumn({
          carrier: params?.data as SpareCarrier,
          setNotificationMessage: setNotificationMessage,
        });
      },
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
  ];
  return { columnDefsLeft };
};

export const useTableDataRight = ({
  setNotificationMessage,
}: {
  setNotificationMessage: (message: string) => void;
}) => {
  const navigate = useNavigate();

  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefsRight: ColDef[] = [
    {
      field: "StandName",
      headerName: "Stand",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "ActionRequired",
      headerName: "Action Required",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      flex: 1,
      cellStyle: cellStyle,
      valueGetter: (params) =>
        actionRequiredCodes.find(
          (code) => code.Code === (params.data as any).ActionRequired
        )?.Name,
      cellRenderer: ActionRequiredTag,
      cellRendererParams: (params: ICellRendererParams) => ({
        status: actionRequiredCodes.find(
          (code) => code.Code === (params.data as any).ActionRequired
        ),
      }),
    },
    {
      field: "Spares",
      headerName: "Spares",
      minWidth: 20,
      flex: 2,
      cellStyle: cellStyle,
      cellRenderer: (params: ICellRendererParams) => {
        const spares = (params.data as SpareCarrier)?.SparesFormatted || [];

        return spares.length > 0 ? (
          <div style={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
            {spares.map(
              (
                spare: { status: string; name: string; id: number | null },
                index: number
              ) =>
                index === 0 ? (
                  <Chip
                    label={spare.name}
                    variant="outlined"
                    color={"primary"}
                    size="small"
                    onClick={() => {
                      navigate(`/carriers-assembly/${spare.id}`);
                    }}
                  />
                ) : (
                  <CarrierChip key={index} carrier={spare} />
                )
            )}
          </div>
        ) : null;
      },
      tooltipValueGetter: (params) =>
        (params.data as SpareCarrier)?.Spares ?? "",
    },
    {
      field: "Comments",
      headerName: "Comments",
      cellRenderer: (params: any) => {
        return CommentsColumn({
          carrier: params?.data as SpareCarrier,
          setNotificationMessage: setNotificationMessage,
        });
      },
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
  ];
  return { columnDefsRight };
};
