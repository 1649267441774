import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, TextField } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Arbor } from "../../../../configuration/models/parts/Arbor";
import { Part } from "../../models/Part";
import { getArbors } from "../../repositories/parts/ArborRepository";

type useArborsProps = {
  partId: number;
  onError: (error: Error) => void;
  onSuccess?: (data: Arbor[]) => void;
};

export const useArbors = ({ partId, onError, onSuccess }: useArborsProps) => {
  return useQuery<Arbor[], Error>({
    queryKey: ["arbors", partId],
    queryFn: () => getArbors(partId),
    onError: onError,
    onSuccess: onSuccess,
  });
};

type Props = {
  part: Part | null;
};

export const SpecificArborData = ({ part }: Props) => {
  const [error, setError] = useState("");
  const [arbor, setArbor] = useState<Arbor>();

  const { data: arbors } = useArbors({
    partId: part?.PartId!,
    onError: (error) => setError(error.message),
    onSuccess: (data: Arbor[]) => {
      setArbor(data[0]);
    },
  });

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            value={arbor?.Stands || ""}
            label="Stands"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            value={arbor?.Comments || ""}
            label="Comments"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
