import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Arbor } from "../../../models/parts/Arbor";
import { getArbors } from "../../../repositories/parts/ArborRepository";
import { usePageStyles } from "../../../styles/pageStyles";
import { TableArbor } from "./components/TableArbor";

type useArborsProps = {
  partId?: number;
  onError?: (error: Error) => void;
};

export const useArbors = ({ partId, onError }: useArborsProps) => {
  return useQuery<Arbor[], Error>({
    queryKey: ["arbors", partId],
    queryFn: () => getArbors(partId),
    onError: onError,
  });
};

export const ArborPage = () => {
  const classes = usePageStyles();

  const { partId } = useParams();

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableArbor partId={partId} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
