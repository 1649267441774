import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  colorCircle: {
    width: "26px",
    height: "26px",
    borderRadius: 4,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
  },
});
export const assemblyStatusCodes = [
  { Code: "E", Name: "Empty" },
  { Code: "A", Name: "Assembly In Progress" },
  { Code: "R", Name: "Ready" },
  { Code: "S", Name: "Disassembled In Progress" },
  { Code: "I", Name: "Inhibited" },
];
type StatusTagProps = {
  status: { Code: string; Name: string };
};
export const CarrierAssemblyStatusTag = ({ status }: StatusTagProps) => {
  const classes = useStyles();
  if (!status || !status.Code || !status.Name) {
    return null;
  }
  let backgroundColor;
  if (
    status.Code === assemblyStatusCodes.find((code) => code.Code === "R")?.Code // Ready
  ) {
    backgroundColor = "#009460"; // Green
  } else if (
    status.Code === assemblyStatusCodes.find((code) => code.Code === "I")?.Code // Inhibited
  ) {
    backgroundColor = "#ce2029"; // Red
  } else if (
    status.Code === assemblyStatusCodes.find((code) => code.Code === "A")?.Code // Assembled
  ) {
    backgroundColor = "#004999"; // Blue
  } else if (
    status.Code === assemblyStatusCodes.find((code) => code.Code === "S")?.Code // Dissassembled In Progress
  ) {
    backgroundColor = "#fad215"; // Yellow
  } else backgroundColor = "#eeeeee"; // Grey

  return (
    <Tooltip title={status.Name} arrow>
      <div
        className={classes.colorCircle}
        style={{
          backgroundColor,
          color: status.Code === "E" ? "#2E2E2E" : "white",
        }}
      >
        {status.Code.charAt(0).toUpperCase()}
      </div>
    </Tooltip>
  );
};
