import { Part, PART_INITIAL_VALUES } from "./Part";

export interface Carrier extends Part {
	AssemblyStatusCode: string;
	IsActive: boolean | null;
}

export const CARRIER_INITIAL_VALUES: Carrier = {
	...PART_INITIAL_VALUES,
	AssemblyStatusCode: 'E',
	IsActive: true
};