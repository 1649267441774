import { Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { ErrorModal } from "@dexteel/mesf-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FooterDetailsButtons } from "../../../../../controls/buttons/FooterDetailsButtons";
import { NotificationSnackBar } from "../../../../../controls/snackbars/notification-snackbar";
import { SectionFamilySelector } from "../../../../../shared/selectors";
import { Section, SECTION_INITIAL_VALUES } from "../../../models/Section";
import { upsertSection } from "../../../repositories/SectionRepository";
import { SectionGapsGrid } from "./SectionGapsGrid";

const useStyles = makeStyles(() => ({
  errorLabel: {
    fontSize: 12,
    color: "#F44336",
  },
  infoLabel: {
    fontSize: 12,
    color: "#757575",
  },
}));

type Props = {
  section: Section | null;
  modal: "create" | "update" | "delete" | "";
  onHide: ({
    shouldUpdate,
    close,
    entityId,
  }: {
    shouldUpdate: boolean;
    close: boolean;
    entityId?: number;
  }) => void;
};

export const SectionDetails = ({ section, modal, onHide }: Props) => {
  const classes = useStyles();

  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const succesfullyMessage = `The section was ${modal}d succesfully`;

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    getValues,
    formState: { dirtyFields },
  } = useForm<Section>({
    mode: "onChange",
  });

  const isFormDirty = !(Object.keys(dirtyFields).length === 0);

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(upsertSection, {
    onSuccess: async (id: number) => {
      setNotificationMessage(succesfullyMessage);
      await queryClient.invalidateQueries(["sections"]);
      onHide({ shouldUpdate: true, close: false, entityId: id });
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<Section> = async (data) => {
    setIsSubmitLoading(true);
    if (modal !== "delete") {
      upsertMutation.mutate(data);
    }
  };

  useEffect(() => {
    if (modal !== "") {
      reset(section || SECTION_INITIAL_VALUES);
      setIsSubmitLoading(false);
    }
  }, [modal, section]);

  return (
    <>
      {section === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a section or create a new one</Typography>
        </div>
      ) : (
        <form id="upsert-section-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Controller
                name={"SectionName"}
                control={control}
                rules={{ required: "Name is required" }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="SectionFamilyId"
                rules={{ required: "Family is required" }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <SectionFamilySelector
                    label="Section Family"
                    value={field.value}
                    onChange={field.onChange}
                    disabled={modal === "delete"}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <SectionGapsGrid
              section={section!}
              disabled={modal === "delete"}
            />
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ margin: "10px 15px" }}
            >
              <FooterDetailsButtons
                isSubmitLoading={isSubmitLoading}
                disabled={!isFormDirty}
                onHide={() => onHide({ shouldUpdate: false, close: true })}
              />
            </Grid>
          </Grid>
        </form>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
