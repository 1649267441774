import { makeStyles } from "@material-ui/core";

export const primaryColor = "#3f51b5"
export const backgroundColor  = "#C8DEF7"
export const autocompleteOptionColor  = "#f4978e4f"


export const useAutocompleteOptionStyles = makeStyles((theme) => ({
    optionBox: {
      width: "100%",
      height: "100%",
      padding: theme.spacing(1),
      boxSizing: "border-box",
      margin: 0,
      "&.MuiAutocomplete-option": {
        padding: 0,
      },
    },
  }));