import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Part } from "../models/Part";

export const getParts = async () => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];


	const resp = await apiService.callV2("[M2].[GetParts]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as Part[];
	}
	else {
		throw new Error(resp.message || "Error fetching parts");
	}
};