import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { UniversalRoll } from "../../models/parts/UniversalRoll";

export const getUniversalRolls = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

const resp = await apiService.callV2("[M2].[GetUniversalRolls]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as UniversalRoll[];
	}
	else {
		throw new Error(resp.message || "Error fetching arbors");
	}
};
export const upsertUniversalRoll = async (roll: UniversalRoll, forceAssembly: boolean) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: roll.PartId },
		{ name: "PartName", value: roll.PartName },
		{ name: "LocationId", value: roll.LocationId },
		{ name: "RollPosition", value: roll.RollPosition },
		{ name: "RollStatusCode", value: roll.RollStatusCode },
		{ name: "DonutPartId", value: roll.DonutPartId },
		{ name: "ArborPartId", value: roll.ArborPartId },
		{ name: "Comments", value: roll.Comments },
		{ name: "ForceAssemble", value: forceAssembly },
	];

	const resp = await apiService.callV2("[M2].[UpsertUniversalRoll]", parameters);

	if (resp.ok){
		return get(resp, "data.returnValue", null) as number;
	}
	else {
		throw new Error(resp.message || "Error upserting roll");
	}
};

export const deleteUniversalRoll = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	const resp = await apiService.callV2("[M2].[DeleteRoll]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error deleting roll");
	}
};