import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Carrier } from "../../../models/parts/Carrier";
import { getCarriers } from "../../../repositories/parts/CarrierRepository";
import { usePageStyles } from "../../../styles/pageStyles";
import { TableCarrier } from "./components/TableCarrier";

export const useCarriers = (
  partId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<Carrier[], Error>({
    queryKey: ["carriers", partId],
    queryFn: () => getCarriers(partId),
    onError: onError,
  });
};

export const CarrierPage = () => {
  const classes = usePageStyles();

  const { partId } = useParams();

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableCarrier partId={partId} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
