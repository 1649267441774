import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { UniversalRoll } from "../../../models/parts/UniversalRoll";
import { getUniversalRolls } from "../../../repositories/parts/UniversalRollRepository";
import { usePageStyles } from "../../../styles/pageStyles";
import { TableUniversalRoll } from "./components/TableUniversalRoll";

export const useUniversalRolls = (
  partId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<UniversalRoll[], Error>({
    queryKey: ["universalRolls", partId],
    queryFn: () => getUniversalRolls(partId),
    onError: onError,
  });
};

export const UniversalRollPage = () => {
  const classes = usePageStyles();

  const { partId } = useParams();

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableUniversalRoll partId={partId} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
