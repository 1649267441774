export interface Rollset {
	RollsetId: number | null;
    TopRollName: string | null;
    TopRollId: number | null;
    BottomRollName: string | null;
    BottomRollId: number | null;
    Comments: string;
}

export const ROLLSET_INITIAL_VALUES: Rollset = {
	RollsetId: null,
	TopRollName: '',
	TopRollId: null,
    BottomRollName: '',
	BottomRollId: null,
	Comments: '',
};

export interface RollsetRolls {
	RollsetId: number | null;
    PartId: number | null;
    IsDefaultTopRoll: boolean;
    IsDefaultBottomRoll: boolean;
}