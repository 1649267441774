import { Part, PART_INITIAL_VALUES } from "./Part";

export interface Chock extends Part {
	RebuildTimestamp: Date | null;
	BearingChangeTimestamp: Date | null;
	IsActive: boolean | null;
}

export const CHOCK_INITIAL_VALUES: Chock = {
	...PART_INITIAL_VALUES,
	RebuildTimestamp: null,
	BearingChangeTimestamp: null,
	IsActive: true
};