import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { DelayForPareto } from "../models/DelayForPareto";

type getDelaysForParetoProps = {
  startDate: Date;
  endDate: Date;
  delayCodeId?: number | null;
  delayCategoryId?: number | null;
  shiftId?: number | null;
  crew?: string | null;
  delayAreaAssetId?: number | null;
};

export const getDelaysForPareto = async ({
  startDate,
  endDate,
  delayCodeId,
  delayCategoryId,
  shiftId,
  crew,
  delayAreaAssetId,
}: getDelaysForParetoProps) => {
  const apiService = new MESApiService();
  const parameters: spParameter[] = [];

  parameters.push({ name: "Start", value: startDate });
  parameters.push({ name: "End", value: endDate });
  parameters.push({
    name: "DelayAreaAssetId",
    value: delayAreaAssetId || null,
  });

  parameters.push({
    name: "DelayCategoryId",
    value: delayCategoryId || null,
  });
  parameters.push({
    name: "DelayCodeId",
    value: delayCodeId || null,
  });
  parameters.push({
    name: "ShiftId",
    value: shiftId || null,
  });
  parameters.push({
    name: "Crew",
    value: crew || null,
  });

  const resp = await apiService.callV2(
    "[DEL].[GetDelaysForPareto]",
    parameters
  );

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as DelayForPareto[];
  } else {
    throw new Error(resp.message || "Error fetching delays for pareto");
  }
};
