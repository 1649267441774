import { getMomentTz, MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { CassetteChock } from "../../models/parts/CassetteChock";

const moment = getMomentTz();

export const getCassetteChocks = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetCassetteChocks]", parameters);

	if (resp.ok){

		let rows = get(resp, "data.tables[0].rows", []);
	
		rows = rows.map((cassetteChock: CassetteChock) => ({
		  ...cassetteChock,
		  RebuildTimestamp: cassetteChock.BearingChangeTimestamp ? moment.utc(cassetteChock["RebuildTimestamp"]).toDate() : null,
		  BearingChangeTimestamp: cassetteChock.BearingChangeTimestamp ? moment.utc(cassetteChock["BearingChangeTimestamp"]).toDate() : null
		}));
		
		return rows as CassetteChock[]
	} else {
		throw new Error(resp.message || "Error fetching chocks")
	}
};
export const upsertCassetteChock = async (cassetteChock: CassetteChock) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: cassetteChock.PartId },
		{ name: "PartName", value: cassetteChock.PartName },
		{ name: "LocationId", value: cassetteChock.LocationId },
		{ name: "Comments", value: cassetteChock.Comments },
	];

	if (cassetteChock.RebuildTimestamp)
		parameters.push({ name: "RebuildTimestamp", value: cassetteChock.RebuildTimestamp });
		
	if (cassetteChock.RebuildTimestamp)
		parameters.push({ name: "BearingChangeTimestamp", value: cassetteChock.BearingChangeTimestamp });

	const resp = await apiService.callV2("[M2].[UpsertCassetteChock]", parameters);

	if (resp.ok){
		return get(resp, "data.returnValue", null) as number;
	}
	else {
		throw new Error(resp.message || "Error upserting cassette chock");
	}
};

export const deleteCassetteChock = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	const resp = await apiService.callV2("[M2].[DeleteCassetteChock]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error deleting cassette chock");
	}
};