import { MenuOptionType } from "@dexteel/mesf-core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ContentCopyIcon from "@material-ui/icons/FileCopy";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";

import { CassetteChock } from "../../../../models/parts/CassetteChock";

type Props = {
  setSelectedRowData: (c: CassetteChock) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
};
export const useCassetteChockOptionsFunctions = ({
  setSelectedRowData,
  setShowModal,
}: Props) => {
  const getMenuOptions = (data: CassetteChock) => {
    let options: MenuOptionType[] = [];
    options = options.concat([
      {
        name: "New Cassette Chock",
        key: "new_cassette_chock",
        onClick: () => {
          setShowModal("create");
        },
        icon: <PlaylistAddIcon />,
      },
    ]);
    if (data) {
      options = options.concat([
        {
          name: "Edit Cassette Chock",
          key: "edit_cassette_chock",
          onClick: () => {
            setSelectedRowData(data);
            setShowModal("update");
          },
          icon: <EditIcon />,
        },
        {
          name: "Delete Cassette Chock",
          key: "delete_cassette_chock",
          onClick: () => {
            setSelectedRowData(data);
            setShowModal("delete");
          },
          icon: <DeleteIcon />,
        },
        {
          name: "Duplicate Cassette Chock",
          key: "duplicate_cassette_chock",
          onClick: () => {
            setSelectedRowData({
              ...data,
              PartName: `${data.PartName} (Copy)`,
              RFIDTag: null,
              IsDuplicate: true,
            });
            setShowModal("create");
          },
          icon: <ContentCopyIcon />,
        },
      ]);
    }
    return options;
  };
  return {
    getMenuOptions,
  };
};
