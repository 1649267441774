import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { donutStatusCodes } from "../../../../../../shared/selectors/DonutStatusSelector";
const useStyles = makeStyles({
  colorCircle: {
    width: "26px",
    height: "26px",
    borderRadius: 4,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontWeight: "bold",
  },
});
type StatusTagProps = {
  status: { Code: string; Name: string };
};
export const DonutStatusTag = ({ status }: StatusTagProps) => {
  const classes = useStyles();
  if (!status || !status.Code || !status.Name) {
    return null;
  }
  let backgroundColor;
  if (
    status.Code === donutStatusCodes.find((code) => code.Code === "R")?.Code // Ready
  ) {
    backgroundColor = "#009460"; // Green
  } else if (
    status.Code === donutStatusCodes.find((code) => code.Code === "U")?.Code // Under Repair
  ) {
    backgroundColor = "#fad215"; // Yellow
  } else if (
    status.Code === donutStatusCodes.find((code) => code.Code === "D")?.Code // Damaged
  ) {
    backgroundColor = "#ce2029"; // Red
  }

  return (
    <Tooltip title={status.Name} arrow>
      <div className={classes.colorCircle} style={{ backgroundColor }}>
        {status.Code.charAt(0).toUpperCase()}
      </div>
    </Tooltip>
  );
};
