import { ColDef } from "ag-grid-enterprise";
import { partTypeCodes } from "../components/TableParts";
import { Part } from "../models/Part";

export const useTableData = () => {
  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefs: ColDef[] = [
    {
      field: "PartName",
      headerName: "Name",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "TagId",
      headerName: "Tag",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "LocationName",
      headerName: "Location",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "Sections",
      headerName: "Sections",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "Stands",
      headerName: "Stands",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "PartTypeCode",
      headerName: "Type",
      minWidth: 20,
      flex: 1,
      valueGetter: (params) =>
        partTypeCodes.find(
          (part) => part.Code === (params.data as Part).PartTypeCode
        )?.Name,
      cellStyle: cellStyle,
    },
  ];
  return { columnDefs };
};
