import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import { Button, Grid, Typography } from "@material-ui/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { CenteredLazyLoading } from "../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../controls/snackbars/notification-snackbar";
import { Carrier, CARRIER_INITIAL_VALUES } from "../../models/Carrier";
import { assemblyCarrier } from "../../repositories/CarrierAssemblyRepository";

type Props = {
  carrier: Carrier;
  show: boolean;
  onHide: (shouldUpdate: boolean) => void;
};

export const CarrierDisassemblyModal = ({ carrier, show, onHide }: Props) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (show) {
      setIsLoading(false);
    }
  }, [show]);

  const queryClient = useQueryClient();
  const disassemblyMutation = useMutation(assemblyCarrier, {
    onSuccess: () => {
      queryClient.invalidateQueries([`carriersAssembly`]);
      setMessage(`The carrier was disassembled successfully`);
      onHide(true);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsLoading(false),
  });

  const onDisassembly = async (data: Carrier) => {
    setIsLoading(true);
    disassemblyMutation.mutate({
      ...CARRIER_INITIAL_VALUES,
      PartId: carrier?.PartId,
      LocationId: carrier?.LocationId,
    });
  };

  return (
    <>
      <MesfModal
        title={`CARRIER DISASSEMBLY`}
        open={show}
        handleClose={() => onHide(false)}
        id={`disassebmly-carrier-modal`}
        maxWidth="md"
      >
        <MesfModal.Content style={{ padding: "15px 30px" }}>
          {isLoading && <CenteredLazyLoading />}
          {!isLoading && (
            <>
              <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                  <Typography>
                    Are you sure you want to disassemble carrier
                    <strong> {carrier?.PartName}</strong>?
                  </Typography>
                  <Typography>
                    All linked parts will be disassembled and will be ready for
                    future assembly.
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </MesfModal.Content>
        <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item md={3} xs={12} style={{ margin: 0 }}>
              <Button
                fullWidth
                variant="contained"
                color="default"
                onClick={() => onHide(false)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item md={3} xs={12} style={{ margin: 0 }}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => onDisassembly(carrier)}
              >
                DISASSEMBLY
              </Button>
            </Grid>
          </Grid>
        </MesfModal.Actions>
      </MesfModal>
      <NotificationSnackBar message={message} onHide={() => setMessage("")} />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error Deleting Profile"
      />
    </>
  );
};
