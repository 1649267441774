import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { SectionFamily } from "../models/SectionFamily";

export const getSectionFamilies = async (sectionFamilyId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (sectionFamilyId)
		parameters.push({ name: "SectionFamilyId", value: sectionFamilyId });

	const resp = await apiService.callV2("[M2].[GetSectionFamilies]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as SectionFamily[];
	}
	else {
		throw new Error(resp.message || "Error fetching section families");
	}
};
