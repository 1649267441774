import { Location, TreeCode } from "../../models/Location";

type Props = {
    locations: Location[] | undefined;
    setLocationsTree: (root: any) => void;
}

export const useHookLocations = ({locations = [], setLocationsTree}: Props) => {
    const buildTreeLocations = (nodes: TreeCode[]) => {
      if (nodes === undefined) return;
      //tree build functions
      const idMapping = nodes.reduce((acc: any, el: any, i: number) => {
        acc[el.id] = i;
        return acc;
      }, {});
      let root;
      nodes.forEach((el) => {
        // Handle the root element
        if (el.parentId === null) {
          root = el;
          return;
        }
        // Use our mapping to locate the parent element in our data array
        const parentEl = nodes[idMapping[el.parentId]];
        // Handle the case where the element has no parent
        if (!parentEl) {
          console.log(el);
          return;
        }
        // Add our current el to its parent's `children` array
        if (!parentEl["children"]) {
          parentEl["children"] = [];
        }
        parentEl["children"] = [...(parentEl["children"] || []), el];
      });
      setLocationsTree(root as any);
    };
  
    const loadFilterLocations = () => {
      const nodes = locations?.map(
        (location: Location) =>
          ({
            id: location.LocationId,
            parentId: location.ParentLocationId,
            name: location.LocationName,
            isLeaf: false,
            isActive: true,
            children: [],
          } as TreeCode)
      );
      buildTreeLocations(nodes);
    };
  
    return { loadFilterLocations };
  };