export interface CatalogSectionFamily {
	SectionFamilyId: number | null;
	SectionFamilyName: string;
	IsActive: boolean | null;
}

export const CATALOG_SECTION_FAMILY_INITIAL_VALUES: CatalogSectionFamily = {
	SectionFamilyId: null,
	SectionFamilyName: '',
	IsActive: true
};