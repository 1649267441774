import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, makeStyles } from "@material-ui/core";
import { GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";

import { useTableData } from "../hooks/hooks";
import { DelayByShift } from "../models/DelayByShift";
import { useGridStyles } from "../styles/gridStyles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: "100%",
    height: "calc(100% - 20px)",
    "& .ag-root-wrapper": {
      border: "none",
    },
    "& .ag-header": {
      borderTop: "1px solid #e0e0e0",
    },
  },
  gridWrapper: {
    border: "4px solid #ccc",
    borderRadius: 10,
    padding: 10,
    height: "calc(100vh - 300px)",
    minHeight: "calc(100vh - 300px)",
    width: "100%",
    margin: "20px 0 10px",
  },
}));

type Props = {
  isLoading: boolean;
  rows: DelayByShift[];
  gridApi: GridApi | null;
  setGridApi: (params: GridApi) => void;
};

export const DelaysReportTable = ({
  isLoading,
  rows,
  gridApi,
  setGridApi,
}: Props) => {
  const classes = useStyles();
  const gridStyles = useGridStyles();

  const [error, setError] = useState("");

  const { columns } = useTableData({});

  useEffect(() => {
    if (isLoading && !rows) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, rows, gridApi]);

  return (
    <div onContextMenu={(e) => e.preventDefault()}>
      <div className={classes.tableContainer}>
        <Grid container className={gridStyles.root}>
          <Grid item md={12} xs={12} className={"ag-theme-balham"}>
            <div className={classes.gridWrapper}>
              <AgGridReact
                rowData={rows}
                columnDefs={columns}
                rowSelection="single"
                pagination={false}
                animateRows
                rowHeight={35}
                headerHeight={35}
                singleClickEdit
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                paginationAutoPageSize
                onGridReady={(params) => setGridApi(params.api)}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <ErrorModal error={error} onHide={() => setError("")} />
    </div>
  );
};
