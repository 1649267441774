import { Box, Paper, styled, Tooltip, Typography } from "@material-ui/core";

const ChannelSquare = styled(Paper)(() => ({
  width: 20,
  height: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  margin: 4,
  cursor: "default",
  "&:hover": {
    opacity: 0.9,
  },
}));

export const RollChannelStatus = ({
  channelsString,
}: {
  channelsString: string;
}) => {
  if (!channelsString) {
    return null;
  }
  const channels = channelsString.split(",");

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 1,
        width: "100%",
        padding: "8px 0",
      }}
    >
      <Typography variant="body2">Available Channels</Typography>
      {channels.map((status, index) => (
        <Tooltip
          key={index}
          title={`Channel ${index + 1}: ${
            status === "R" ? "Ready" : "Damaged"
          }`}
        >
          <ChannelSquare
            elevation={2}
            style={{ backgroundColor: status === "R" ? "green" : "red" }}
          ></ChannelSquare>
        </Tooltip>
      ))}
    </Box>
  );
};
