import { Part, PART_INITIAL_VALUES } from "./Part";

export interface Donut extends Part {
	DonutStatusCode: string;
	Diameter: number | null;
	NewDiameter: number | null;
	ScrapDiameter: number | null;
	Stands: '',
	StandsId: '',
	Sections: '',
	SectionsId: '',
	IsActive: boolean | null;
}

export const DONUT_INITIAL_VALUES: Donut = {
	...PART_INITIAL_VALUES,
	DonutStatusCode: 'R',
	Diameter: null,
	NewDiameter: null,
	ScrapDiameter: null,
	Stands: '',
	StandsId: '',
	Sections: '',
	SectionsId: '',
	IsActive: true
};