import { Button, Grid, makeStyles } from "@material-ui/core";
import FormatListBulletedSharpIcon from "@material-ui/icons/FormatListBulletedSharp";
import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { Carrier } from "../../models/Carrier";
import {
  assemblyStatusCodes,
  CarrierAssemblyStatusTag,
} from "../components/CarrierAssemblyStatusTag";

const useStyles = makeStyles(() => ({
  buttons: {
    opacity: 0,
    transition: "opacity 0.1s ease-in-out",
    ".ag-row-hover &": {
      opacity: 1,
    },
    ".ag-row-focus &": {
      opacity: 1,
    },
    ".ag-row-selected &": {
      opacity: 1,
    },
  },
}));
type Props = {
  showContextMenu: Function;
};
export const useTableData = ({ showContextMenu }: Props) => {
  const classes = useStyles();
  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefs: ColDef[] = [
    {
      field: "PartName",
      headerName: "Name",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "SectionName",
      headerName: "Section",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "ScheduledLocationName",
      headerName: "Stand",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "TopRollName",
      headerName: "Top Roll",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "BottomRollName",
      headerName: "Bottom Roll",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "RollStatusCode",
      headerName: "Status",
      minWidth: 20,
      maxWidth: 70,
      flex: 1,
      cellStyle: cellStyle,
      valueGetter: (params) =>
        assemblyStatusCodes.find(
          (code) => code.Code === (params.data as Carrier).AssemblyStatusCode
        )?.Name,
      cellRenderer: CarrierAssemblyStatusTag,
      cellRendererParams: (params: ICellRendererParams) => ({
        status: assemblyStatusCodes.find(
          (code) => code.Code === (params.data as Carrier).AssemblyStatusCode
        ),
      }),
    },
    {
      field: "-",
      cellRenderer: (params: ICellRendererParams) => (
        <Grid
          container
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className={classes.buttons}
        >
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() =>
                showContextMenu(event, params.data, "carrierAssemblyContext")
              }
              title="Options"
            >
              <FormatListBulletedSharpIcon
                style={{ height: "auto" }}
                color="action"
              />
            </Button>
          </Grid>
        </Grid>
      ),
      wrapText: false,
      autoHeight: false,
      wrapHeaderText: false,
      headerName: "",
      sortable: false,
      flex: 1,
      maxWidth: 180,
      filter: false,
      autoHeaderHeight: true,
    },
  ];
  return { columnDefs };
};
