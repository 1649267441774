import { ErrorModal } from "@dexteel/mesf-core";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { getShifts } from "../../repositories/DelaysReportRepository";

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiInputBase-root": {
    height: "40px",
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
    "&:before, &:after": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  "& .MuiInputLabel-root": {
    transform: "translate(14px, 12px) scale(1)",
    "&.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
      backgroundColor: "white",
      padding: "0 4px",
    },
  },
  "& .MuiSelect-select": {
    padding: "8px 14px",
  },
  "& .MuiPaper-root": {
    marginTop: "8px",
  },
  "& .MuiMenuItem-root": {
    padding: "10px 14px",
    fontSize: "14px",
  },
});

export const useShifts = (onError?: (error: Error) => void) => {
  return useQuery<String[], Error>({
    queryKey: ["shifts"],
    queryFn: () => getShifts(),
    onError: onError,
  });
};

type Props = {
  value: string | null;
  onChange: (value: string | null) => void;
};

export const ShiftsFilter: React.FC<Props> = ({ value, onChange }) => {
  const [error, setError] = useState("");

  const { data: shiftsList } = useShifts((error) => setError(error.message));

  return (
    <>
      <StyledFormControl variant="outlined">
        <InputLabel id="shift-select-label">Shift</InputLabel>
        <Select
          labelId="shift-select-label"
          id="shift-select"
          value={value === null ? "all" : value}
          label="Shift"
          onChange={(e) => {
            const value = e.target.value;
            onChange(value === "all" ? null : (value as string));
          }}
        >
          <MenuItem value="all">All</MenuItem>
          {shiftsList
            ? shiftsList.map((shift: any) => (
                <MenuItem key={`shift-${shift.Shift}`} value={shift.Shift}>
                  {shift.Shift}
                </MenuItem>
              ))
            : null}
        </Select>
      </StyledFormControl>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
