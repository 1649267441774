import { ErrorModal } from "@dexteel/mesf-core";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { DelayAreaAsset } from "../../codes/models/CatalogDelayCode";
import { getDelayAreas } from "../../report/repositories/DelaysReportRepository";

const StyledFormControl = styled(FormControl)<{ customHeight: number }>(
  ({ customHeight }) => ({
    width: "100%",
    "& .MuiInputBase-root": {
      height: `${customHeight}px`,
      borderRadius: "4px",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      "&:hover": {
        borderColor: "rgba(0, 0, 0, 0.87)",
      },
      "&:before, &:after": {
        display: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiInputLabel-root": {
      transform: "translate(14px, 12px) scale(1)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75)",
        backgroundColor: "white",
        padding: "0 4px",
      },
    },
    "& .MuiSelect-select": {
      padding: "8px 14px",
    },
    "& .MuiPaper-root": {
      marginTop: "8px",
    },
    "& .MuiMenuItem-root": {
      padding: "10px 14px",
      fontSize: "14px",
    },
  })
);

export const useDelayAreas = (onError?: (error: Error) => void) => {
  return useQuery<DelayAreaAsset[], Error>({
    queryKey: ["delayAreas"],
    queryFn: () => getDelayAreas(),
    onError: onError,
  });
};

type Props = {
  value: number | null;
  onChange: (assetId: number | null) => void;
  height?: number;
};

export const DelayAreaFilter: React.FC<Props> = ({
  value,
  onChange,
  height = 40,
}) => {
  const [error, setError] = useState("");

  const { data: delayAreas } = useDelayAreas((error) =>
    setError(error.message)
  );

  return (
    <>
      <StyledFormControl variant="outlined" customHeight={height}>
        <InputLabel id="delay-area-label">Delay Area</InputLabel>
        <Select
          labelId="delay-area-label"
          id="delay-select"
          value={value === null ? "all" : value}
          label="Delay Area"
          onChange={(e) => {
            const value = e.target.value;
            onChange(value === "all" ? null : (value as number));
          }}
        >
          <MenuItem value="all">All</MenuItem>
          {delayAreas
            ? delayAreas.map((area) => (
                <MenuItem key={area.AssetId} value={area.AssetId}>
                  {area.AssetName}
                </MenuItem>
              ))
            : null}
        </Select>
      </StyledFormControl>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
